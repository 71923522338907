import React from 'react';

import {
  Button,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Td,
  Tr,
  useDisclosure,
  DetailList,
} from '@ecoinvent/ui';
import { format } from 'date-fns';

import { Review } from 'hooks/data/datasets/documentation/types';

import reviewConfig from './reviewConfig';

const ReviewRow = ({ review_date, reviewer_name, ...rest }: Review) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const correctedReviewerName = reviewer_name === '[System]' ? 'ecoinvent' : reviewer_name;

  return (
    <Tr>
      <Td>{correctedReviewerName}</Td>
      <Td>{format(new Date(review_date), 'PPP')}</Td>
      <Td width={'100px'}>
        <Button size="sm" variant="link" onClick={onOpen}>
          Show Details
        </Button>
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reviewer details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <DetailList config={reviewConfig} data={{ review_date, reviewer_name: correctedReviewerName, ...rest }} />
            </ModalBody>
            <ModalFooter>
              <Button variant={'subtle'} size="sm" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ReviewRow;
