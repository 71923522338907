import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { FetchDatasetParams } from '../types';

import { LCIResultResponse } from './types';

const fetchLciResults = async (params: FetchDatasetParams) => {
  const { data } = await api.get(`/spold/lci_results?`, { params });

  return data;
};

const useLciResults = (id?: string) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<LCIResultResponse, AxiosError>(
    ['lciResults', id, version, system_model],
    () => fetchLciResults({ dataset_id: id as string, version, system_model }),
    { enabled: !!id }
  );
};

export default useLciResults;
