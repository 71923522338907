import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { SpoldDataFetchParams, SpoldDataResponse, SpoldSearchParams } from './types';

const fetchSpoldData = async (params: SpoldDataFetchParams) => {
  const response = await api.post<SpoldDataResponse>(`/search/${params.ver}/${params.system_model}`, params);
  return response.data;
};

const useSpoldData = (params: SpoldSearchParams) => {
  const { ver, system_model } = useVersionInfo();
  return useQuery<SpoldDataResponse, AxiosError>(['search', ver, system_model, params], () =>
    fetchSpoldData({ ...params, ver, system_model })
  );
};

export default useSpoldData;
