import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import api from 'api/api';

import { File } from './types';

const fetchReports = async () => {
  const { data } = await api.get(`/files/reports`);

  return data;
};

const useReports = () => {
  const auth = useAuth();
  return useQuery<File[], AxiosError>('reports', fetchReports, { enabled: auth.isAuthenticated });
};

export default useReports;
