import { Box, Divider, Heading, Icon, Skeleton, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import { formatDistance } from 'date-fns';
import { MdAccessTime, MdAccountTree, MdArticle, MdPrecisionManufacturing, MdPublic } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import tray from 'assets/tray.png';
import useRecentDatasets from 'hooks/data/datasets/useRecentDatasets';

const RecentViewed = () => {
  const { data: recentDatasets = [], isLoading } = useRecentDatasets();
  const navigate = useNavigate();

  const handleRouteToDetailPage = (item: any) => {
    //TODO: Fix item type
    navigate(`/${item.version}/${item.system_model}/dataset/${item.index}/documentation`);
  };

  const borderColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack spacing={2} width="100%" maxWidth={{ xl: '400px' }}>
      <Heading as="h4" size="sm">
        Recent activity
      </Heading>
      {isLoading ? (
        <Skeleton height={200} />
      ) : (
        <Stack borderRadius={'md'} border={'1px solid'} borderColor={borderColor}>
          {recentDatasets?.length > 0 ? (
            <Stack spacing={0} divider={<Divider orientation="horizontal" />}>
              {recentDatasets?.slice(0, 4).map((item, idx) => {
                return (
                  <Stack
                    p={2}
                    spacing={2}
                    key={idx}
                    cursor={'pointer'}
                    _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
                    onClick={() => handleRouteToDetailPage(item.dataset)}
                  >
                    <Stack spacing={2} fontSize="caption" direction="row" alignItems="center" color={labelColor}>
                      <Icon as={MdAccessTime} fontSize="inherit" />
                      <Text fontSize="xs" color={labelColor}>
                        {formatDistance(new Date(), new Date(item.viewed_at))} ago
                      </Text>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="flex-start">
                      <Icon as={MdArticle} fontSize="md" color="green.500" sx={{ mt: '4px' }} />
                      <Text fontSize={'sm'} fontWeight={'medium'}>
                        {item.dataset.activity_name}
                      </Text>
                    </Stack>
                    <Stack direction="row" spacing={1} fontSize={'xs'}>
                      <Stack overflow={'hidden'} spacing={1} flexGrow={0} direction="row" alignItems="center">
                        <Icon as={MdPrecisionManufacturing} fontSize="sm" opacity={0.4} />
                        <Text fontSize="inherit" color={labelColor}>
                          {item.dataset.reference_product}
                        </Text>
                      </Stack>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Icon as={MdPublic} fontSize="sm" opacity={0.4} />
                        <Text fontSize="inherit" color={labelColor}>
                          {typeof item.dataset.geography === 'string'
                            ? item.dataset.geography
                            : item.dataset.geography.short_name}
                        </Text>
                      </Stack>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Icon as={MdAccountTree} fontSize="sm" opacity={0.4} />
                        <Text fontSize="inherit" color={labelColor}>
                          {item.dataset.version} - {item.dataset.system_model}
                        </Text>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Stack alignItems="center" spacing={4} p={3}>
              <Box>
                <img src={tray} alt="" />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Text as="h4" fontWeight={'medium'}>
                  There's nothing here
                </Text>
                <Text fontSize={'sm'} color={labelColor}>
                  Your recently viewed datasets will appear here.
                </Text>
              </Box>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default RecentViewed;
