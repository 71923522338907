import { Stack, useToast, Text, Heading } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';

import { ExportFile } from 'hooks/data/datasets/types';
import useDownloadFile from 'hooks/data/files/useDownloadFiles';
import useDownloadSpoldFile from 'hooks/data/files/useDownloadSpoldFile';
import useVersionInfo from 'hooks/useVersionInfo';
import DownloadFileContent from 'pages/Export/components/DownloadFileContent';
import LoadingToast from 'pages/Files/LoadingToast';

type Props = { isLoading?: boolean };

const Export = ({ isLoading }: Props) => {
  const { mutateAsync: downloadSpoldFile } = useDownloadSpoldFile();
  const { mutateAsync: downloadFile } = useDownloadFile();
  const { ver: version, system_model } = useVersionInfo();
  const { spold_id } = useParams();
  const toast = useToast();

  const onDownload = async (file: ExportFile) => {
    mixpanel.track('Export Dataset', { file: file.name, version, system_model, spold_id });
    const toastRef = toast({
      description: 'Your file download is being prepared, please wait...',
      duration: 9000,
      isClosable: true,
      render: LoadingToast,
    });

    file.is_s3_presigned ? await downloadFile(file.url) : await downloadSpoldFile(file.url);

    toast.close(toastRef);
  };

  return (
    <Stack>
      <Heading as="h4" fontSize={'xl'} fontWeight={'semibold'}>
        Export Dataset
      </Heading>
      <Text fontSize={'sm'}>Here you can download the content of a dataset, in various different formats.</Text>
      <DownloadFileContent onDownload={onDownload} isLoading={isLoading} />
    </Stack>
  );
};
export default Export;
