import { useSearchParams } from 'react-router-dom';

import { FilterMap } from './types';

type ExtendedFilterMap = FilterMap & { query?: string; currentPage?: number; pageSize?: number };

const defaultFilterValues: ExtendedFilterMap = {
  query: '',
  geography: [],
  isic_section: [],
  isic_class: [],
  activity_type: [],
  sector: [],
  currentPage: 1,
  pageSize: 5,
};

const useSearchFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterValues: ExtendedFilterMap = {
    query: searchParams.get('query') || defaultFilterValues.query,
    currentPage: Number(searchParams.get('currentPage')) || defaultFilterValues.currentPage,
    pageSize: Number(searchParams.get('pageSize')) || defaultFilterValues.pageSize,
    geography: searchParams.getAll('geography') || defaultFilterValues.geography,
    isic_section: searchParams.getAll('isic_section') || defaultFilterValues.isic_section,
    isic_class: searchParams.getAll('isic_class') || defaultFilterValues.isic_class,
    activity_type: searchParams.getAll('activity_type') || defaultFilterValues.activity_type,
    sector: searchParams.getAll('sector') || defaultFilterValues.sector,
  };

  const resetFilters = () => {
    setSearchParams({
      ...defaultFilterValues,
      query: filterValues.query as string,
    } as any);
  };

  const setFilterValue = (field: keyof ExtendedFilterMap, value: string | string[] | number) => {
    setSearchParams({ ...filterValues, [field]: value } as any);
  };

  const setBulkFilterValues = (values: Partial<ExtendedFilterMap>) => {
    setSearchParams({ ...filterValues, ...values } as any);
  };

  return { filterValues, resetFilters, setFilterValue, setBulkFilterValues };
};

export default useSearchFilters;
