import { DEFAULT_SYSTEM_MODEL, MOST_RECENT_VERSION } from 'utilities/constants/versions';

import { ReleaseVersion, ReleaseVersionMap } from './types';

export const reduceVersionsToMap = (data: ReleaseVersion[]): ReleaseVersionMap =>
  data.reduce((map, item) => {
    const { version } = item;
    map[version] = map[version] ? [...map[version], item] : [item];

    return map;
  }, {} as ReleaseVersionMap);

export const deduceLatestVersionAndSystemModel = (versions: ReleaseVersion[]): ReleaseVersion => {
  const latestVersion = versions.filter((v) => !v.version.includes('Test') && v.is_public)?.[0];

  if (!latestVersion)
    return {
      version: MOST_RECENT_VERSION,
      system_model: DEFAULT_SYSTEM_MODEL,
      release_date: new Date().toISOString(),
      is_public: true,
    };

  const versionMap = reduceVersionsToMap(versions);

  const doesLatestVersionHaveDefaultSystemModel =
    versionMap[latestVersion.version].filter((v) => v.system_model === DEFAULT_SYSTEM_MODEL).length > 0;

  const systemModelToChoose = doesLatestVersionHaveDefaultSystemModel ? DEFAULT_SYSTEM_MODEL : latestVersion.system_model;

  return {
    version: latestVersion.version ?? MOST_RECENT_VERSION,
    system_model: systemModelToChoose ?? DEFAULT_SYSTEM_MODEL,
    release_date: latestVersion.release_date,
    is_public: latestVersion.is_public,
  };
};
