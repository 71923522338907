import React, { useState } from 'react';

import { Tr, Td, HStack, Text } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import useVersionInfo from 'hooks/useVersionInfo';

import { FilteredExchange } from './types';

type Props = { item: FilteredExchange };

const LciResultRow = ({ item }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { ver: version, system_model } = useVersionInfo();
  const { spold_id } = useParams();

  return (
    <Tr onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Td>{item.name}</Td>
      <Td>{`${item.compartment}, ${item.sub_compartment}`}</Td>
      <Td>
        <HStack>
          <Text>
            {item.amount.toExponential(4)} {item.unit}
          </Text>
          <CopyToClipboardButton
            value={item.amount}
            opacity={isHovered ? 1 : 0}
            onValueCopied={() => {
              mixpanel.track('Copy Dataset Value', {
                version,
                system_model,
                spold_id,
                type: 'lci_result',
                exchange: item.originalName,
                compartment: `${item.compartment}, ${item.sub_compartment}`,
              });
            }}
          />
        </HStack>
      </Td>
    </Tr>
  );
};

export default LciResultRow;
