import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import api from 'api/api';

import { ReleaseVersion, ReleaseVersionMap } from '../types';
import { reduceVersionsToMap } from '../utils';

const fetchReleaseVersions = async () => {
  const { data } = await api.get(`/web/versions`);

  return data;
};

export const useRawReleaseVersions = <T = ReleaseVersionMap>(opts?: UseQueryOptions<ReleaseVersion[], AxiosError, T>) => {
  return useQuery<ReleaseVersion[], AxiosError, T>('releaseVersions', fetchReleaseVersions, opts);
};

const useReleaseVersions = (opts?: UseQueryOptions<ReleaseVersion[], AxiosError, ReleaseVersionMap>) => {
  return useRawReleaseVersions<ReleaseVersionMap>({ select: reduceVersionsToMap, ...opts });
};

export default useReleaseVersions;
