import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';

import { appConfig } from 'utilities/env';

type Props = { title?: string };

const PageHead = ({ title }: Props) => {
  const pageTitle = useMemo(() => (title ? `${title} | ${appConfig.documentTitle}` : appConfig.documentTitle), [title]);

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content="EcoInvent EcoQuery" />
    </Helmet>
  );
};

export default PageHead;
