import { format } from 'date-fns';

export function convertISOToDate(ISOdate: Date) {
  return format(new Date(ISOdate), 'PPP');
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function humanizeCamelCase(string: string) {
  const words = string.match(/[A-Za-z][a-z]*/g) || [];
  return capitalizeFirstLetter(words.join(' ').toLowerCase());
}
export function parseJwt(token: string) {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}

export function addingUniqueId(list: Array<any>) {
  if (list.length > 0) {
    list.forEach((item, i) => {
      item.id = i + 1;
    });
  }
  return list;
}
