import { useMemo } from 'react';

import { IconButton, Stack, Table, Tbody, Td, Thead, Tr, Text, Th, Icon, Skeleton, useColorModeValue } from '@ecoinvent/ui';
import { BiDownload } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import { ExportFile, ExportFileType } from 'hooks/data/datasets/types';
import useExportFileList from 'hooks/data/datasets/useExportFileList';
import useVersionInfo from 'hooks/useVersionInfo';

type Props = {
  onDownload: (type: ExportFile) => void;
  isLoading?: boolean;
};

const skeletonFile: ExportFile = {
  name: 'skeleton file',
  description: 'This is a sample file used for the loading skeleton',
  type: ExportFileType.PDF,
  url: 'https://ecoquery.ecoinvent.org',
  is_s3_presigned: false,
};

const DownloadFileContent = ({ onDownload: onDownload, isLoading }: Props) => {
  const { spold_id } = useParams();
  const { system_model } = useVersionInfo();
  const { data = [], isLoading: areFilesLoading } = useExportFileList(spold_id);
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');

  const isAnythingLoading = isLoading || areFilesLoading;

  // only show reports and unit processes for unlinked datasets, otherwise show all files
  const newFilesToExport = useMemo(() => {
    const filesToExport: ExportFile[] = isAnythingLoading ? [skeletonFile] : data;

    return filesToExport.filter((file) => {
      if (system_model !== 'undefined') return true;

      if (!/system_model=undefined/i.test(file.url)) return false;

      return ['Dataset Report', 'Unit Process'].includes(file.name);
    });
  }, [system_model, isAnythingLoading, data]);

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <Skeleton display={'flex'} alignSelf={'flex-start'} isLoaded={!isAnythingLoading}>
              File Description
            </Skeleton>
          </Th>
          <Th>
            <Skeleton isLoaded={!isAnythingLoading}>Type</Skeleton>
          </Th>
          <Th>
            <Skeleton isLoaded={!isAnythingLoading}>Download</Skeleton>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {newFilesToExport.map((file) => (
          <Tr key={file.name}>
            <Td>
              <Stack>
                <Skeleton isLoaded={!isAnythingLoading}>
                  <Text fontWeight={'medium'}>{file.name}</Text>
                </Skeleton>
                <Skeleton isLoaded={!isAnythingLoading}>
                  <Text fontSize={'sm'} color={grayTextColor}>
                    {file.description}
                  </Text>
                </Skeleton>
              </Stack>
            </Td>
            <Td fontSize={'sm'} fontWeight={'medium'}>
              <Skeleton isLoaded={!isAnythingLoading}>{file.type}</Skeleton>
            </Td>
            <Td>
              <Skeleton isLoaded={!isAnythingLoading}>
                <IconButton
                  variant={'subtle'}
                  size="sm"
                  icon={<Icon as={BiDownload} />}
                  aria-label="download"
                  onClick={() => onDownload(file)}
                />
              </Skeleton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
export default DownloadFileContent;
