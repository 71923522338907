import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import api from 'api/api';
import { downloadFile } from 'utilities/helpers/downloads';

const startDownloadFile = async (response: AxiosResponse<any>) => {
  const contentDispositionHeader = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDispositionHeader);
  let fileName = 'unamed.txt';
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, '');
  }

  const url_link = URL.createObjectURL(new Blob([response.data]));
  downloadFile(fileName, url_link);
};

const fetchFile = async (download_url: string) => {
  const response = await api.get(download_url, {
    responseType: 'blob',
  });
  return response;
};

const initiateFileDownload = async (download_url: string) => {
  const response = await fetchFile(download_url);
  startDownloadFile(response);
};

const useDownloadSpoldFile = () => {
  return useMutation(initiateFileDownload);
};

export default useDownloadSpoldFile;
