import React from 'react';

import { Text, useColorModeValue } from '@ecoinvent/ui';

type Props = { details: string };

const SystemModelDetails = ({ details }: Props) => {
  const systemModelColor = useColorModeValue('gray.600', 'gray.400');
  return (
    <Text color={systemModelColor} fontSize={'sm'} textAlign={'left'}>
      {details}
    </Text>
  );
};

export default SystemModelDetails;
