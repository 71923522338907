import { BoxProps, Image, Modal, ModalContent, ModalOverlay, Stack, useDisclosure } from '@ecoinvent/ui';

const DocumentationImage = (props: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack>
      <Image
        overflow="hidden"
        component="img"
        borderRadius={'lg'}
        maxWidth="500px"
        maxHeight="500px"
        {...props}
        onClick={() => onOpen()}
        display={'block'}
        cursor={'pointer'}
      />
      <Modal size="6xl" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Image borderRadius={'lg'} {...props} />
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default DocumentationImage;
