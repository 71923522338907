import { Heading, Table, TableContainer, Tbody, Tr, Td, Stack } from '@ecoinvent/ui';
export const pedigreeMatrixLabels = [
  'Reliability',
  'Completeness',
  'Temporal accuracy',
  'Geographical accuracy',
  'Technological accuracy',
];

type Props = {
  pedigreeMatrix: string;
};

const DataQuality = ({ pedigreeMatrix }: Props) => {
  if (!pedigreeMatrix) return null;

  const parsedMatrix = JSON.parse(pedigreeMatrix) as number[];

  return (
    <Stack>
      <Heading as={'h4'} fontSize={'xl'} fontWeight={'semibold'}>
        Data Quality
      </Heading>
      <TableContainer>
        <Table size={'sm'} aria-label="a dense table">
          <Tbody>
            {parsedMatrix.map((value, index) => (
              <Tr key={index}>
                <Td sx={{ width: 200 }}>{pedigreeMatrixLabels[index]}</Td>
                <Td>{value}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DataQuality;
