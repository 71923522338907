import { useMemo, useState } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Stack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
  Icon,
} from '@ecoinvent/ui';
import { BiSearch } from 'react-icons/bi';
import { useAuth } from 'react-oidc-context';

import PageHead from 'components/shared/PageHead';
import { ReportVersion } from 'hooks/data/files/types';
import useFileAccess from 'hooks/data/files/useFileAccess';
import useReports from 'hooks/data/files/useReports';
import DocumentationMarkdownRenderer from 'pages/Documentation/components/DocumentationMarkdownRenderer';
import ReportsTable from 'pages/Reports/ReportsTable';

import loadingReports from './loadingData';

const Reports = () => {
  const [search, setSearch] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const auth = useAuth();
  const { isLoading, data } = useReports();
  const { data: accessData } = useFileAccess();

  const hasReportAccess = accessData?.has_access ?? false;

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const dataToRender = useMemo(() => {
    return data
      ? data
          .filter((report) => report.name.toLowerCase().includes(search.toLowerCase()))
          .filter((report) => (tabIndex === 0 ? report.version === ReportVersion.V3 : report.version !== ReportVersion.V3))
      : loadingReports;
  }, [search, data, tabIndex]);

  const headerText = `ecoinvent version 3 datasets are fully documented, including data sources. The Reports section is a repository of
  additional information for selected projects. For more information and documentation, please refer to our website, [ecoinvent.org](https://ecoinvent.org).`;

  const tabNames = ['Version 3', 'Older Versions'];

  return (
    <Stack align={'center'}>
      <PageHead title="Reports" />
      <Stack maxW="container.xl" w="90%" spacing={7}>
        <Heading as="h1" size="2xl">
          Reports
        </Heading>
        <DocumentationMarkdownRenderer>{headerText}</DocumentationMarkdownRenderer>
        {!auth.isAuthenticated ? (
          <Alert background={'blue.50'} status="info" variant={'left-accent'} borderRadius="md">
            <AlertIcon />
            <AlertDescription>
              To view and download reports, please{' '}
              <Button
                variant={'link'}
                colorScheme="blue"
                onClick={() => auth.signinRedirect({ redirect_uri: window.location.href })}
              >
                sign in.
              </Button>
            </AlertDescription>
          </Alert>
        ) : (
          <Stack>
            {!isLoading && !data?.length ? (
              <Alert status="info" variant="left-accent">
                <AlertIcon /> {hasReportAccess ? 'No reports found.' : "Your licence doesn't give you access to reports."}
              </Alert>
            ) : (
              <Stack>
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                  <Stack
                    spacing={{ base: 7, md: 1 }}
                    direction={{ base: 'column-reverse', md: 'row' }}
                    justify="space-between"
                    align={{ base: 'stretch', md: 'center' }}
                  >
                    <InputGroup maxWidth={{ base: 'auto', md: '400px' }}>
                      <InputLeftElement pointerEvents="none">
                        <Icon as={BiSearch} color="gray.300" />
                      </InputLeftElement>
                      <Input placeholder="Filter reports" onChange={(e) => setSearch(e.target.value)} />
                    </InputGroup>
                    <TabList alignSelf={{ base: 'flex-end', md: 'auto' }}>
                      {tabNames.map((tabName) => (
                        <Tab key={tabName}>{tabName}</Tab>
                      ))}
                    </TabList>
                  </Stack>
                  <TabPanels>
                    {tabNames.map((name) => (
                      <TabPanel key={name} py={4} px={0}>
                        <ReportsTable data={dataToRender} isLoading={isLoading} />
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Reports;
