import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import { hasAuthParams, useAuth } from 'react-oidc-context';

import { setupMixpanel } from 'utilities/auth/user';

type Props = {
  children: React.ReactNode;
};

const ApiInterceptor = ({ children }: Props) => {
  const { isLoading, activeNavigator, signinSilent, events, removeUser } = useAuth();

  // This is a guard to make sure we've set up mixpanel and the api headers before rendering the app for the first time
  const [isSetupDone, setIsSetupDone] = useState(false);

  const performAuthSetup = useCallback(async () => {
    if (isLoading || hasAuthParams() || !!activeNavigator || isSetupDone) return;

    const refreshedUser = await signinSilent({ redirect_uri: window.location.href });
    refreshedUser && setupMixpanel(refreshedUser);

    setIsSetupDone(true);
  }, [activeNavigator, isLoading, signinSilent, isSetupDone]);

  useEffect(() => {
    events.addUserSignedOut(removeUser);

    performAuthSetup();

    return () => {
      events.removeUserSignedOut(removeUser);
    };
  }, [performAuthSetup, removeUser, events]);

  return isSetupDone ? <>{children}</> : null;
};

export default ApiInterceptor;
