import { Alert, AlertDescription, AlertIcon, Link } from '@ecoinvent/ui';

const YearReviewBanner = () => {
  return (
    <Alert status="info">
      <AlertIcon />
      <AlertDescription fontSize={'sm'}>
        We have just released our Year-in-Review 2023 - check it out{' '}
        <Link colorScheme="blue" isExternal href="https://ecoinvent.org/blog/year-in-review-2023/">
          here
        </Link>
        .
      </AlertDescription>
    </Alert>
  );
};

export default YearReviewBanner;
