import mixpanel from 'mixpanel-browser';
import { User } from 'oidc-client-ts';

import { queryClient } from 'utilities/data/queryClient';

export const resetUserData = () => {
  mixpanel.reset();
  queryClient.resetQueries();
};

export const setupMixpanel = (user: User) => {
  mixpanel.identify(user.profile.email as string);
  mixpanel.people.set({
    $email: user.profile.email,
    $first_name: user.profile.given_name,
    $last_name: user.profile.family_name,
    $picture: user.profile.picture,
  });
};
