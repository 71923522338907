import React, { useCallback } from 'react';

import { Select, Skeleton, Stack, Text, Pagination } from '@ecoinvent/ui';

type AdvancedSearchPaginationProps = {
  totalHits: number;
  currentPage: number;
  itemsPerPage: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  isLoading?: boolean;
};

const ActivitySearchPagination = ({
  totalHits,
  currentPage,
  itemsPerPage,
  onChangePage,
  onChangePageSize,
  isLoading,
}: AdvancedSearchPaginationProps) => {
  const onPageChange = useCallback(
    (page: number) => {
      onChangePage(page);
    },
    [onChangePage]
  );

  return (
    <Stack width="100%" alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
      <Stack direction={'row'} alignItems={'center'} spacing={3}>
        {isLoading ? (
          <>
            <Skeleton width="100px" />
            <Skeleton width="50px" />
          </>
        ) : (
          <>
            <Text as="label" htmlFor="itemsPerPage" fontSize={'xs'} whiteSpace={'nowrap'}>
              Items per page
            </Text>
            <Select
              id="itemsPerPage"
              size="sm"
              value={itemsPerPage}
              onChange={(e) => onChangePageSize(Number(e.target.value))}
              borderRadius={'md'}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </>
        )}
      </Stack>
      {isLoading ? <Skeleton width="100px" /> : <Text fontSize={'sm'}>{totalHits} total results</Text>}

      <Pagination
        isLoading={isLoading}
        totalHits={totalHits}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </Stack>
  );
};

export default ActivitySearchPagination;
