import { File } from 'hooks/data/files/types';

const loadingReports: File[] = [
  {
    name: 'Loading...',
    uuid: '1234-1234-gmail',
    last_modified: 'never',
    size: 1,
    description: 'no',
  },
];

export default loadingReports;
