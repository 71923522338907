import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useLatestVersion from 'hooks/data/versions/useLatestVersion';

const Root = () => {
  const { data: latestVersion, isLoading } = useLatestVersion();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || !latestVersion) return;

    navigate(`/${latestVersion?.version}/${latestVersion?.system_model}/search`, { replace: true });
  }, [latestVersion, navigate, isLoading]);

  return null;
};

export default Root;
