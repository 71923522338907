import { RelatedDataset } from 'hooks/data/datasets/types';

const filterDataType = (data?: RelatedDataset[], dataType?: string) => {
  return data?.filter((item) => item.related_dataset_type === dataType) ?? [];
};

export const buildDropdownData = (data: RelatedDataset[] | undefined) => [
  {
    label: 'Version',
    options: filterDataType(data, 'version'),
    gridProps: { xs: 12, sm: 2, md: 1 },
  },
  {
    label: 'System model',
    options: filterDataType(data, 'system_model'),
    gridProps: { xs: 12, sm: 3, md: 2 },
  },
  {
    label: 'Activity name',
    options: filterDataType(data, 'activity'),
    gridProps: { xs: 12, sm: 5, md: 4 },
  },
  {
    label: 'Geography',
    options: filterDataType(data, 'geography'),
    gridProps: { xs: 12, sm: 6, md: 2 },
  },
  {
    label: 'Reference product',
    options: filterDataType(data, 'product'),
    gridProps: { xs: 12, sm: 6, md: 3 },
  },
];
