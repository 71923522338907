import { Text, DetailListConfig, Link } from '@ecoinvent/ui';

import { DataGeneratorAndPublication } from 'hooks/data/datasets/documentation/types';

const sourceTypeTextMap: Record<string, string> = {
  '0': 'Undefined',
  '1': 'Article',
  '2': 'Chapters in anthology',
  '3': 'Separate publication',
  '4': 'Measurement on site',
  '5': 'Oral communication',
  '6': 'Personal written communication',
  '7': 'Questionnaires',
};

const publishedInTextMap: Record<string, string> = {
  '0': 'Data as such not published elsewhere',
  '1': 'The data of some unit processes or subsystems are published',
  '2': 'Data has been published entirely in',
};

const dataGeneratorConfig: DetailListConfig<DataGeneratorAndPublication> = [
  {
    label: 'Name',
    key: 'person_name',
  },
  {
    label: 'Email',
    key: 'person_email',
    renderItem: (email: string) => <Link href={`mailto:${email}`}>{email}</Link>,
  },
  {
    label: 'Published in',
    key: 'data_published_in',
    renderItem: (value: string) => <Text>{publishedInTextMap[value] ?? value}</Text>,
  },
  {
    label: 'Source Type',
    key: 'source_type',
    renderItem: (value: string) => <Text>{sourceTypeTextMap[value] ?? value}</Text>,
  },
  {
    label: 'Published Year',
    key: 'published_source_year',
  },
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'First Author',
    key: 'published_source_first_author',
  },
  {
    label: 'Additional Authors',
    key: 'additional_authors',
  },
];

export default dataGeneratorConfig;
