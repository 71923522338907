import React, { useState } from 'react';

import { Tr, Text, Stack, Td, HStack } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import { DirectContribution } from 'hooks/data/datasets/directContribution/types';
import { LciaMethod } from 'hooks/data/datasets/lcia/types';
import useVersionInfo from 'hooks/useVersionInfo';

type Props = {
  contributor: DirectContribution;
  indicatorId: number;
  category: string;
  indicatorName: string;
  method: LciaMethod;
};

const DirectContributionRow = ({ method, contributor, indicatorId, category, indicatorName }: Props) => {
  const { ver: version, system_model } = useVersionInfo();
  const { spold_id } = useParams();
  const [hovering, setHovering] = useState(false);

  return (
    <Tr onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <Td fontWeight={'semibold'}>
        {contributor?.relative_contribution ? (contributor?.relative_contribution * 100).toFixed(5) + ' %' : null}
      </Td>
      <Td>
        <Stack>
          <Text>
            <b>{contributor.type === 'elementary' ? 'Elementary' : 'Intermediate'} exchange: </b>
            {contributor.amount.toExponential(4) + ' ' + contributor.unit}{' '}
            {contributor.type === 'elementary' ? contributor.meta.name : contributor.meta.subcomp}
          </Text>
          <Text>
            <b>{contributor.type === 'elementary' ? 'Compartment' : 'Supplier'}: </b>{' '}
            {contributor.type === 'elementary'
              ? `${contributor.meta.comp}, ${contributor.meta.subcomp}`
              : `${contributor.meta.name} - ${contributor.meta.comp}`}
          </Text>
        </Stack>
      </Td>
      <Td>
        <HStack>
          <Text>{contributor?.impact.toExponential(4) ?? null}</Text>
          <CopyToClipboardButton
            value={contributor.impact}
            onValueCopied={() => {
              mixpanel.track('Copy Dataset Value', {
                version,
                system_model,
                spold_id,
                indicator_id: indicatorId,
                type: 'direct_contribution',
                supplier: contributor.meta.name,
                exchange_index: contributor.index,
                category,
                indicator_name: indicatorName,
                lcia_method: method.method_name,
              });
            }}
            opacity={hovering ? 1 : 0}
          />
        </HStack>
      </Td>
    </Tr>
  );
};

export default DirectContributionRow;
