import React from 'react';

import { Heading, Stack, Text, Skeleton } from '@ecoinvent/ui';

import { DocumentationConfigItem } from 'pages/Documentation/types';

type Props<T> = Pick<DocumentationConfigItem<T>, 'label' | 'value' | 'renderValue' | 'isLoading'>;

const DocumentationItem = <T,>({ label, value, renderValue, isLoading }: Props<T>) => {
  if (!value || (value as Array<T>)?.length === 0 || Object.keys(value as object).length === 0) return null;

  return (
    <Stack>
      <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
        <Heading as="h4" fontWeight={'bold'} fontSize={'xl'}>
          {label}
        </Heading>
      </Skeleton>
      <Skeleton isLoaded={!isLoading}> {renderValue?.(value) ?? <Text>{value as string}</Text>}</Skeleton>
    </Stack>
  );
};

export default DocumentationItem;
