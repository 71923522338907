import React from 'react';

import { Box, Stack, Heading } from '@ecoinvent/ui';

import { IntermediateExchange } from 'hooks/data/datasets/exchanges/types';

type Props = { item: IntermediateExchange & { key: string } };

const ProductVolume = ({ item }: Props) => {
  return (
    <Stack>
      <Heading as={'h4'} fontSize={'xl'} fontWeight={'semibold'}>
        Production Volume
      </Heading>
      <Box>
        {item.productionVolumeAmount && (
          <Box>
            <b>Amount:</b> {item.productionVolumeAmount}
          </Box>
        )}
        {item.productionVolumeComment && (
          <Box>
            <b>Comment:</b> {item.productionVolumeComment}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default ProductVolume;
