import React from 'react';

import { Stack } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import useSearchFilters from 'hooks/data/search/useSearchFilters';
import useSpoldData from 'hooks/data/search/useSpoldData';
import useVersionInfo from 'hooks/useVersionInfo';

import RecentViewed from '../RecentViewed';

import ActivitySearchBar from './ActivitySearchBar';
import ActivitySearchFilters from './ActivitySearchFilters';
import ActivitySearchPagination from './ActivitySearchPagination';
import ActivitySearchResultTable from './ActivitySearchResultTable';

const ActivitySearch = () => {
  const navigate = useNavigate();

  const {
    setFilterValue,
    setBulkFilterValues,
    filterValues: { query, currentPage = 1, pageSize = 5, ...otherFilters },
  } = useSearchFilters();

  const auth = useAuth();

  const { ver: version, system_model } = useVersionInfo();

  const payload = {
    query: query ?? '',
    filters: {
      ...otherFilters,
    },
    from_: pageSize * (currentPage - 1),
    limit: pageSize,
  };

  const { data, isLoading, error } = useSpoldData(payload);

  const totalHits = data?.hits.total.value ?? 0;
  const hits = data?.hits.hits ?? [];

  const isNotFound = error?.response?.status === 404;

  if (isNotFound) {
    navigate('/404');
  }

  return (
    <Stack spacing={6} direction={{ base: 'column', xl: 'row' }} justifyContent={'space-between'} maxW={'100%'}>
      <Stack spacing={5} w="100%">
        <ActivitySearchBar />
        <ActivitySearchResultTable
          isLoading={isLoading}
          version={version}
          system_model={system_model}
          data={hits}
          totalHits={totalHits}
          onResultClick={(spold_id) => {
            mixpanel.track('Search Result Clicked', {
              datasetId: spold_id,
              version: version,
              system_model,
              query,
              filters: otherFilters,
            });
          }}
        />
        <ActivitySearchPagination
          totalHits={totalHits}
          itemsPerPage={pageSize}
          currentPage={currentPage}
          isLoading={isLoading}
          onChangePage={(page) => {
            mixpanel.track('Advanced Search Pagination', { page });
            setFilterValue('currentPage', page);
          }}
          onChangePageSize={(pageSize) => {
            mixpanel.track('Advanced Search Page Size', { pageSize });
            setBulkFilterValues({ currentPage: 1, pageSize: pageSize });
          }}
        />
      </Stack>
      <Stack spacing={6} maxW={{ base: '100%', xl: '300px' }} width={'100%'}>
        <ActivitySearchFilters isLoading={isLoading} filters={otherFilters} aggregations={data?.aggregations} />
        {auth.isAuthenticated && <RecentViewed />}
      </Stack>
    </Stack>
  );
};

export default ActivitySearch;
