import React from 'react';

import { Box, Heading, Stack, Text } from '@ecoinvent/ui';

import { IntermediateExchange } from 'hooks/data/datasets/exchanges/types';

type Props = { data: IntermediateExchange['classification'] };

const Classification = ({ data }: Props) => {
  const intermediateExchangeKeys = Object.keys(data) as Array<keyof IntermediateExchange['classification']>;
  return (
    <Stack>
      <Heading as={'h4'} fontSize={'xl'} fontWeight={'semibold'}>
        Classification
      </Heading>
      {intermediateExchangeKeys.map((keyName) => (
        <Box key={keyName}>
          <Text>
            <b>{keyName}:</b> {data[keyName]}
          </Text>
        </Box>
      ))}
    </Stack>
  );
};

export default Classification;
