import { DetailListConfig, Link, Text } from '@ecoinvent/ui';
import { format } from 'date-fns';

import { Review } from 'hooks/data/datasets/documentation/types';

const reviewConfig: DetailListConfig<Review> = [
  {
    label: 'Name',
    key: 'reviewer_name',
  },
  {
    label: 'Email',
    key: 'reviewer_email',
    renderItem: (email: string) => <Link href={`mailto:${email}`}>{email}</Link>,
  },
  {
    label: 'Date',
    key: 'review_date',
    renderItem: (date: Date) => <Text>{format(new Date(date), 'PPP')}</Text>,
  },
  {
    label: 'Major Release',
    key: 'reviewed_major_release',
  },
  {
    label: 'Minor Release',
    key: 'reviewed_minor_release',
  },
  {
    label: 'Major Revision',
    key: 'reviewed_major_revision',
  },
  {
    label: 'Minor Revision',
    key: 'reviewed_minor_revision',
  },
];

export default reviewConfig;
