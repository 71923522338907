import React from 'react';

import { Box, Heading, Link, Stack, Text } from '@ecoinvent/ui';

import PageHead from 'components/shared/PageHead';
import { appConfig } from 'utilities/env';

const registerUrl = `${appConfig.kcAuthority}/protocol/openid-connect/registrations?client_id=apollo-ui&response_type=code&redirect_uri=${appConfig.accountsUrl}/upgrade`;

const Register = () => {
  React.useEffect(() => {
    window.location.href = registerUrl;
  }, []);

  return (
    <Box>
      <PageHead title="Redirecting..." />
      <Stack spacing={5} alignItems="center" margin="0 auto" py={5}>
        <Heading as="h3" size="2xl">
          Hold on...
        </Heading>
        <Text>
          You are being redirected, if this doesn't happen, <Link href={registerUrl}>click here</Link>
        </Text>
      </Stack>
    </Box>
  );
};

export default Register;
