import { Stack, Table, Text, Thead, Tr, Th, Tbody, Td, Skeleton, IconButton, Icon } from '@ecoinvent/ui';
import { useToast } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiDownload } from 'react-icons/bi';

import { File } from 'hooks/data/files/types';
import useDownloadFile from 'hooks/data/files/useDownloadFiles';
import LoadingToast from 'pages/Files/LoadingToast';

function shortenBytes(n: number) {
  const k = n > 0 ? Math.floor(Math.log2(n) / 10) : 0;
  const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
  const count = Math.floor(n / Math.pow(1024, k));
  return `${count}${rank}`;
}

type Props = {
  data: File[];
  isLoading?: boolean;
};

const ReportsTable = ({ data, isLoading }: Props) => {
  const toast = useToast();

  const { mutateAsync: handleDownload } = useDownloadFile();

  const handleDownloadFile = async (file_uuid: File['uuid']) => {
    const toastRef = toast({
      description: 'Preparing report download, please wait...',
      duration: 9000,
      isClosable: true,
      render: LoadingToast,
    });
    try {
      mixpanel.track('Download report', { file_uuid });
      await handleDownload(`/files/${file_uuid}`);
    } finally {
      toast.close(toastRef);
    }
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th pl={0}>File Description</Th>
          <Th>Size</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {data.map((release_file, idx) => (
          <Tr data-testid={`report-item-${idx}`} key={idx}>
            <Td width="100%" pl={0}>
              <Stack spacing={1}>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight={'semibold'}>{release_file.name}</Text>
                </Skeleton>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontSize={'sm'}>{release_file.description}</Text>
                </Skeleton>
              </Stack>
            </Td>
            <Td whiteSpace={'nowrap'} fontSize={'sm'}>
              <Skeleton isLoaded={!isLoading}>{shortenBytes(release_file.size)}</Skeleton>
            </Td>
            <Td pr={0}>
              <Skeleton isLoaded={!isLoading}>
                <IconButton
                  variant={'subtle'}
                  size="sm"
                  aria-label="download"
                  icon={<Icon as={BiDownload} />}
                  onClick={() => handleDownloadFile('report/' + release_file.uuid)}
                />
              </Skeleton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ReportsTable;
