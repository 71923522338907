import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import api from 'api/api';
import { FileAccessResponse } from 'hooks/data/files/types';

const fetchFileAccess = async () => {
  const { data } = await api.get(`/files/check_any_access`);

  return data;
};

const useFileAccess = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<FileAccessResponse, AxiosError>(['fileAccess', isAuthenticated], fetchFileAccess, {
    enabled: !!isAuthenticated,
  });
};

export default useFileAccess;
