import React from 'react';

import { Box, Link, Stack, Text, Heading } from '@ecoinvent/ui';
import { useAuth } from 'react-oidc-context';

import PageHead from 'components/shared/PageHead';
import { appConfig } from 'utilities/env';

const Login = () => {
  const { signinRedirect } = useAuth();
  const redirect_url = appConfig.kcRedirectUri;

  React.useEffect(() => {
    signinRedirect({ redirect_uri: redirect_url });
  }, [signinRedirect, redirect_url]);

  return (
    <Box>
      <PageHead title="Redirecting..." />
      <Stack spacing={5} alignItems="center" margin="0 auto" py={5}>
        <Heading as="h3" size="2xl">
          Hold on...
        </Heading>
        <Text>
          You are being redirected, if this doesn't happen{' '}
          <Link onClick={() => signinRedirect({ redirect_uri: redirect_url })}>click here</Link>
        </Text>
      </Stack>
    </Box>
  );
};

export default Login;
