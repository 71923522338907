import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';

import { ReleaseVersion } from '../types';
import { useRawReleaseVersions } from '../useReleaseVersions';
import { deduceLatestVersionAndSystemModel } from '../utils';

const useLatestVersion = (opts?: UseQueryOptions<ReleaseVersion[], AxiosError, ReleaseVersion>) => {
  return useRawReleaseVersions<ReleaseVersion>({
    select: deduceLatestVersionAndSystemModel,
    ...opts,
  });
};

export default useLatestVersion;
