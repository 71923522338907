import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import api from 'api/api';

import { FileResponse } from './types';

const fetchFiles = async () => {
  const { data } = await api.get(`/files`);

  return data;
};

const useFiles = () => {
  const auth = useAuth();
  return useQuery<FileResponse[], AxiosError>('files', fetchFiles, { enabled: auth.isAuthenticated });
};

export default useFiles;
