import React from 'react';

import { Button, IconButton, Menu, MenuButton, MenuList, Skeleton, Stack, Text, useDisclosure } from '@ecoinvent/ui';
import { BiMenu } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';

import useMenuUrlLinks from 'hooks/useMenuUrlLinks';

type Props = { isLoading?: boolean };

const DatasetTabs = ({ isLoading }: Props) => {
  const { items: sm_menuItems, handleRouting } = useMenuUrlLinks();
  const { onClose, isOpen, onOpen } = useDisclosure();

  const location = useLocation();

  const items = sm_menuItems;

  return (
    <Stack spacing={1} as="nav" w="100%">
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          variant="subtle"
          onClick={onOpen}
          alignSelf={'center'}
          display={{ base: 'flex', md: 'none' }}
          as={IconButton}
          icon={<BiMenu />}
        />
        <MenuList>
          {items.map((item) => {
            const isSelected = location.pathname === item.path;
            return (
              <Stack
                key={item.text}
                as={Link}
                to={item.path}
                p={2}
                _hover={{ bg: isSelected ? 'green.400' : 'gray.100' }}
                fontWeight={'medium'}
                fontSize={'sm'}
                cursor={'pointer'}
                color={isSelected ? 'white' : undefined}
                bg={location.pathname === item.path ? 'green.500' : 'transparent'}
                onClick={() => {
                  handleRouting(item);
                  onClose();
                }}
              >
                <Text>{item.text}</Text>
              </Stack>
            );
          })}
        </MenuList>
      </Menu>
      <Skeleton isLoaded={!isLoading}>
        <Stack width="100%" as="nav" spacing={1} display={{ base: 'none', md: 'flex' }} w="100%">
          {items.map((item) => {
            const isSelected = location.pathname === item.path;
            return (
              <Button
                borderRadius={'md'}
                justifyContent={'flex-start'}
                variant={isSelected ? 'subtle' : 'ghost'}
                key={item.text}
                as={Link}
                to={item.path}
                onClick={() => handleRouting(item)}
                p={2}
                fontWeight={isSelected ? 'semibold' : 'normal'}
                fontSize={'sm'}
                cursor={'pointer'}
                colorScheme={isSelected ? 'green' : 'gray'}
              >
                <Text>{item.text}</Text>
              </Button>
            );
          })}
        </Stack>
      </Skeleton>
    </Stack>
  );
};

export default DatasetTabs;
