import React, { useMemo } from 'react';

import {
  Divider,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@ecoinvent/ui';
import { BiSearch, BiX } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import NoResultCard from 'components/UI/NoResultCard';
import useConsumingActivities from 'hooks/data/datasets/consumingActivities/useConsumingActivities';

import ConsumingActivityRow from './ConsumingActivityRow';
import { compoundActivityName } from './utils';

const ConsumingActivities = () => {
  const { spold_id } = useParams();

  const [filterString, setFilterString] = React.useState('');
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');
  const { data, isLoading } = useConsumingActivities(spold_id);

  const activitiesToRender = useMemo(() => {
    return (
      data?.consuming_activities
        .filter((item) => compoundActivityName(item).toLowerCase().includes(filterString.toLowerCase()))
        .slice(0, 100) ?? []
    );
  }, [data, filterString]);

  return (
    <Stack spacing={5}>
      <Stack spacing={5}>
        <Stack>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <Heading as="h4" fontSize={'xl'} fontWeight={'semibold'}>
              Consuming Activities
            </Heading>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <Text fontSize={'sm'}>
              Activities which depend upon this activity, and use it as an input for LCA calculations.
            </Text>
          </Skeleton>
        </Stack>
        <Stack spacing={1}>
          <Skeleton isLoaded={!isLoading}>
            <InputGroup size="sm">
              <InputLeftAddon rounded={'md'}>
                <Icon as={BiSearch} />
              </InputLeftAddon>
              <Input
                rounded={'md'}
                value={filterString}
                onChange={(e) => setFilterString(e.target.value)}
                placeholder="Filter activities..."
              />
              {filterString && (
                <InputRightElement>
                  <IconButton
                    size="sm"
                    icon={<Icon as={BiX} />}
                    variant="ghost"
                    aria-label={'clear input'}
                    color="gray.800"
                    onClick={() => setFilterString('')}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <Text fontSize={'xs'} color={grayTextColor}>{`${
              activitiesToRender?.length ? `Showing first ${activitiesToRender.length} of ` : ''
            }${data?.total} total consuming activities`}</Text>
          </Skeleton>
        </Stack>
        <Stack spacing={1} w="100%">
          {activitiesToRender?.length ? (
            activitiesToRender.map((activity, index) => {
              return (
                <Stack key={activity.spold_id}>
                  <ConsumingActivityRow activity={activity} />
                  {index < activitiesToRender.length - 1 && <Divider />}
                </Stack>
              );
            })
          ) : (
            <Skeleton isLoaded={!isLoading}>
              <NoResultCard>
                {filterString ? 'No consuming activities match your search.' : 'No Consuming Activities'}
              </NoResultCard>
            </Skeleton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConsumingActivities;
