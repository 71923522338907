import { VersionInfoParams } from 'hooks/useVersionInfo/types';

export const computeNewUrlWithVersions = (
  oldParams: VersionInfoParams,
  newParams: VersionInfoParams,
  query?: string,
  newSpoldId?: string,
  oldSpoldId?: string
) => {
  const { ver: oldVer, system_model: oldSystemModel } = oldParams;
  const { ver: newVer, system_model: newSystemModel } = newParams;

  const currentUrl = window.location.pathname;

  const newUrl = currentUrl.replace(oldVer, newVer).replace(oldSystemModel, newSystemModel);

  // if the related spold id is undefined, navigate to the search page
  const newUrlWithSpoldId = newSpoldId
    ? newUrl.replace(`${oldSpoldId}`, `${newSpoldId}`)
    : `/${newVer}/${newSystemModel}/search`;

  return query ? `${newUrlWithSpoldId}?query=${query}` : newUrlWithSpoldId;
};
