import { useToast } from '@ecoinvent/ui';
import { useMutation } from 'react-query';

import api from 'api/api';
import { downloadFile } from 'utilities/helpers/downloads';

import { DownloadFileResponse } from './types';

const fetchDownloadLink = async (url: string) => {
  const { data } = await api.get<DownloadFileResponse>(url);
  return data;
};

const initiateFileDownload = async (url: string) => {
  const linkData = await fetchDownloadLink(url);
  downloadFile(url, linkData.download_url);
};

const useDownloadFile = () => {
  const toast = useToast();

  return useMutation(initiateFileDownload, {
    onError: () => {
      toast({
        title: 'Oh no...',
        description: 'There was an error downloading this file. Please try again later.',
        status: 'error',
        isClosable: true,
      });
    },
  });
};

export default useDownloadFile;
