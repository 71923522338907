import { FileResponse } from 'hooks/data/files/types';

const loadingFiles: FileResponse[] = [
  {
    version_name: 'loading_1',
    version_files: [{ last_modified: 'never', name: 'test', size: 1, uuid: '1234-1234-gmail', description: 'no' }],
    releases: [
      {
        system_model_name: 'loading_3',
        release_files: [{ last_modified: 'never', name: 'test', size: 1, uuid: '1234-1234-gmail', description: 'no' }],
      },
    ],
  },
  {
    version_name: 'loading_2',
    version_files: [{ last_modified: 'never', name: 'test_file', size: 1, uuid: '1234-1234-amgil', description: 'no' }],
    releases: [
      {
        system_model_name: 'loading_3',
        release_files: [{ last_modified: 'never', name: 'test', size: 1, uuid: '0123-4567-amgil', description: 'no' }],
      },
    ],
  },
];

export default loadingFiles;
