import React from 'react';

import { Spinner, Stack } from '@ecoinvent/ui';

import useDirectContribution from 'hooks/data/datasets/directContribution/useDirectContribution';
import { LciaMethod } from 'hooks/data/datasets/lcia/types';

import DirectContributionList from './DirectContributionList';

type Props = {
  spoldId: number;
  indicatorId: number;
  unitName: string;
  category: string;
  indicatorName: string;
  method: LciaMethod;
};

const DirectContributionView = ({ method, spoldId, indicatorId, indicatorName, category, unitName }: Props) => {
  const { data, isLoading } = useDirectContribution({
    dataset_id: spoldId.toString(),
    indicator_id: indicatorId,
  });

  return isLoading ? (
    <Stack py={5} alignItems={'center'}>
      <Spinner size="xl" />
    </Stack>
  ) : (
    <DirectContributionList
      category={category}
      indicatorName={indicatorName}
      indicatorId={indicatorId}
      unitName={unitName}
      exchanges={data?.direct_contributions ?? []}
      method={method}
    />
  );
};

export default DirectContributionView;
