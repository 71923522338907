import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { Suggestion, SuggestedSearchParams, SuggestedDataFetchParams } from './types';

const fetchSuggestions = async (params: SuggestedDataFetchParams) => {
  const response = await api.post(`/search/${params.ver}/${params.system_model}/suggest`, params);
  return response.data;
};

const useSuggestions = (params: SuggestedSearchParams) => {
  const { ver, system_model } = useVersionInfo();
  return useQuery<Suggestion[]>(['suggest', params], () => fetchSuggestions({ ...params, ver, system_model }), {
    enabled: !!params.query,
  });
};

export default useSuggestions;
