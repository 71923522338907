import { Stack, Table, Text, Thead, Tr, Th, Tbody, Td, Skeleton, IconButton, Icon, Heading } from '@ecoinvent/ui';
import { useToast } from '@ecoinvent/ui';
import { BiDownload } from 'react-icons/bi';

import { File } from 'hooks/data/files/types';
import useDownloadFile from 'hooks/data/files/useDownloadFiles';
import LoadingToast from 'pages/Files/LoadingToast';
import { downloadFileFroms3 } from 'pages/Files/utils';

function shortenBytes(n: number) {
  const k = n > 0 ? Math.floor(Math.log2(n) / 10) : 0;
  const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
  const count = Math.floor(n / Math.pow(1024, k));
  return `${count}${rank}`;
}

type Props = {
  categoryName: string;
  data: File[];
  isLoading?: boolean;
  type: 'release' | 'version';
};

const FilesTable = ({ categoryName, data, isLoading, type }: Props) => {
  const toast = useToast();
  const { mutateAsync: handleDownload } = useDownloadFile();

  const handleDownloadFile = async (uuid: string) => {
    const toastRef = toast({
      description: 'Your file download is being prepared, please wait...',
      duration: 9000,
      isClosable: true,
      render: LoadingToast,
    });
    try {
      await downloadFileFroms3(uuid, handleDownload);
    } finally {
      toast.close(toastRef);
    }
  };

  return (
    <>
      <Heading size={'sm'} pb={4}>
        {categoryName}
      </Heading>
      <Table size={'sm'}>
        <Thead>
          <Tr>
            <Th pl={0}>File Name</Th>
            <Th isNumeric>Size</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data.map((file, idx) => (
            <Tr data-testid={`report-item-${idx}`} key={idx}>
              <Td width="100%" pl={0}>
                <Stack spacing={1}>
                  <Skeleton isLoaded={!isLoading}>
                    <Text fontWeight={'semibold'}>{file.name}</Text>
                  </Skeleton>
                  <Skeleton isLoaded={!isLoading}>
                    <Text fontSize={'sm'}>{file.description}</Text>
                  </Skeleton>
                </Stack>
              </Td>
              <Td whiteSpace={'nowrap'} fontSize={'sm'} isNumeric>
                <Skeleton isLoaded={!isLoading}>{shortenBytes(file.size)}</Skeleton>
              </Td>
              <Td pr={0}>
                <Skeleton isLoaded={!isLoading}>
                  <IconButton
                    variant={'subtle'}
                    size="sm"
                    aria-label="download"
                    icon={<Icon as={BiDownload} />}
                    onClick={() => handleDownloadFile(type === 'release' ? `r/${file.uuid}` : `v/${file.uuid}`)}
                  />
                </Skeleton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default FilesTable;
