import React from 'react';

import { Stack, Text, Heading } from '@ecoinvent/ui';

import { IntermediateExchange } from 'hooks/data/datasets/exchanges/types';
import DataQuality from 'pages/Exchanges/components/ExchangeView/ExchangeItem/ExchangeModalContent/DataQuality';

import Classification from './Classification';
import ProductVolume from './ProductVolume';
import Properties from './Properties';
import Uncertainty from './Uncertainty';

type Props = {
  item: IntermediateExchange & { key: string };
};

const ExchangeModalContent = ({ item }: Props) => {
  return (
    <Stack spacing={5}>
      {item.classification && <Classification data={item.classification} />}
      {item.comment && (
        <Stack>
          <Heading as={'h4'} fontSize={'xl'} fontWeight={'semibold'}>
            Comment
          </Heading>
          <Text>{item.comment}</Text>
        </Stack>
      )}
      {item.group === 'Reference product' && (item.productionVolumeComment || item.productionVolumeAmount) && (
        <ProductVolume item={item} />
      )}
      {item.uncertainty?.pedigreeMatrix && <DataQuality pedigreeMatrix={item.uncertainty.pedigreeMatrix} />}
      {item.uncertainty && <Uncertainty data={item.uncertainty} />}
      {item.property && <Properties data={item.property} />}
    </Stack>
  );
};

export default ExchangeModalContent;
