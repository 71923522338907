import React from 'react';

import { Stack, Text, Button, useColorModeValue } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useNavigate, useParams } from 'react-router-dom';

import { ConsumingActivity } from 'hooks/data/datasets/consumingActivities/types';
import useVersionInfo from 'hooks/useVersionInfo';

import { compoundActivityName } from './utils';

type Props = { activity: ConsumingActivity };

const ConsumingActivityRow = ({ activity }: Props) => {
  const { ver, system_model } = useVersionInfo();
  const navigate = useNavigate();
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');
  const { spold_id } = useParams();

  const base_url = `/${ver}/${system_model}/dataset/`;

  const onClick = (target_spold_id: string) => {
    mixpanel.track('Consuming Activity Clicked', { target_spold_id, spold_id });
    navigate(`${base_url}${target_spold_id}/documentation`);
  };

  return (
    <Stack py={1} _first={{ pt: 0 }} direction="row" justifyContent="space-between" alignItems={'center'}>
      <Stack alignItems="flex-start">
        <Stack spacing={0}>
          <Text fontWeight={'medium'}>{compoundActivityName(activity)}</Text>
          <Text fontSize={'sm'}>{activity.reference_product}</Text>
          <Text fontSize={'xs'} color={grayTextColor}>
            Amount used: {activity.amount.toExponential(4)} {activity.unit_name}
          </Text>
        </Stack>
      </Stack>
      <Button size="sm" variant="link" onClick={() => onClick(activity.spold_id)}>
        View Activity
      </Button>
    </Stack>
  );
};

export default ConsumingActivityRow;
