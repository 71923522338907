import { Button, Stack } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { Link as RouterLink } from 'react-router-dom';

import { IntermediateExchange } from 'hooks/data/datasets/exchanges/types';

type Props = {
  baseUrl: string;
  link: IntermediateExchange['link'];
};

const ActivityLink = ({ baseUrl, link }: Props) => {
  const onClick = () => {
    mixpanel.track('Exchange Clicked', { base_url: baseUrl, link });
  };

  return (
    <Stack>
      <Button
        as={RouterLink}
        fontSize={'sm'}
        variant="link"
        alignSelf={'flex-start'}
        px={0}
        onClick={onClick}
        to={`${baseUrl}${link?.url}/documentation`}
      >
        {link?.name}, {link?.geography}
      </Button>
    </Stack>
  );
};

export default ActivityLink;
