import React from 'react';

import { Box, Heading, Stack, Text, UnorderedList, Image, Button } from '@ecoinvent/ui';
import { Link } from 'react-router-dom';

import notFoundArt from 'assets/404.svg';
import PageHead from 'components/shared/PageHead';

const NotFound = () => {
  return (
    <Box>
      <PageHead title="Not Found" />
      <Stack spacing={10} alignItems="center" margin="0 auto" py={10}>
        <Box width="400px" borderRadius={'sm'} overflow={'hidden'} position="relative">
          <Heading fontSize={'9xl'} position={'absolute'} top={'40%'} bottom={0}>
            404
          </Heading>
          <Image position="relative" zIndex={'overlay'} src={notFoundArt} />
        </Box>
        <Stack alignItems="center" spacing={5}>
          <Heading as="h1" size="3xl">
            Oh No...
          </Heading>
          <Text>The page you're looking for doesn't exist.</Text>
          <Stack>
            <Text>This may be because:</Text>
            <UnorderedList>
              <Text as="li">You're searching using a version or system model that doesn't exist</Text>
              <Text as="li">The page you were looking for has moved</Text>
              <Text as="li">You have a typo in your address bar.</Text>
            </UnorderedList>
          </Stack>
        </Stack>

        <Button variant="link" as={Link} to="/">
          Return to ecoQuery
        </Button>
      </Stack>
    </Box>
  );
};

export default NotFound;
