import { useMemo, useRef, useEffect, useState } from 'react';

interface InitialState {
  pageSize?: number;
  currentPage?: number;
  totalItems?: number;
}

interface usePaginationOptions {
  initialState?: InitialState;
}

const usePagination = (options?: usePaginationOptions) => {
  const [currentPage, setCurrentPage] = useState(options?.initialState?.currentPage || 1);
  const [pageSize, setPageSize] = useState(options?.initialState?.pageSize || 10);
  const [totalItems, setTotalItems] = useState(options?.initialState?.totalItems || 0);

  const topOfTableRef = useRef<HTMLDivElement>(null);
  const offset = useMemo(() => (currentPage - 1) * pageSize, [currentPage, pageSize]);

  useEffect(() => {
    topOfTableRef.current?.scrollIntoView();
  }, [currentPage]);

  return {
    offset,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalItems,
    setTotalItems,
    topOfTableRef,
  } as const;
};

export default usePagination;
