import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { FetchDatasetParams } from '../types';

import { LciaMethod } from './types';

const fetchLciaMethods = async (params: Omit<FetchDatasetParams, 'dataset_id'>) => {
  const { data } = await api.get(`/spold/methods`, { params });

  return data;
};

const useLciaMethods = () => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<LciaMethod[], AxiosError>(['methods', version, system_model], () =>
    fetchLciaMethods({ version, system_model })
  );
};

export default useLciaMethods;
