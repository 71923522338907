import { FormControl, Select, FormLabel } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

import { RelatedDataset } from 'hooks/data/datasets/types';

type Props = {
  label: string;
  options: RelatedDataset[];
  url_path: string;
};

const RelatedDropdown = ({ label, options, url_path }: Props) => {
  const navigate = useNavigate();

  const changeReleaseAndNavigate = ({ version, system_model, spold_id }: RelatedDataset) => {
    mixpanel.track('Dataset - View Related Dataset', { version, system_model, spold_id, label });
    navigate(`/${version}/${system_model}/dataset/${spold_id}/${url_path}`);
  };

  const getValue = (options: RelatedDataset[]) => {
    const obj = options.find((value: any) => value.is_current);
    return obj;
  };

  return (
    <FormControl size="sm">
      <FormLabel fontSize={'sm'} htmlFor={`select-${label}`} id={`select-${label}`}>
        {label}
      </FormLabel>
      <Select
        disabled={options?.length <= 1}
        id={`${label}-select`}
        value={getValue(options)?.description}
        onChange={(e) => {
          const selectedOption = options.find((option) => option.description === e.target.value);
          if (!selectedOption) return;

          changeReleaseAndNavigate(selectedOption);
        }}
      >
        {options.map((item) => {
          return (
            <option key={`${item.description}`} value={item.description}>
              {item.description}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default RelatedDropdown;
