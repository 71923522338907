import { Stack, Skeleton, Heading, Text } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import NoResultCard from 'components/UI/NoResultCard';
import useLciaMethods from 'hooks/data/datasets/lcia/useLciaMethods';

import ImpactAssessmentDetails from './ImpactAssessmentDetails';

export const ImpactAssessment = () => {
  const { data, isLoading } = useLciaMethods();
  const { spold_id } = useParams();

  return (
    <Stack spacing={4}>
      <Stack>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isLoading}>
          <Heading as="h4" fontSize={'xl'} fontWeight={'semibold'}>
            Impact Assessment
          </Heading>
        </Skeleton>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isLoading}>
          <Text fontSize={'sm'}>
            The impact scores of the selected activity are calculated from a life cycle perspective using the selected LCIA
            method. Expand a row to view the contributors to each score.
          </Text>
        </Skeleton>
      </Stack>
      {!data && !isLoading ? (
        <NoResultCard p={0}>No Impact scores available.</NoResultCard>
      ) : (
        <ImpactAssessmentDetails isLoading={isLoading} methodList={data} spoldId={parseInt(spold_id ?? '-1')} />
      )}
    </Stack>
  );
};

export default ImpactAssessment;
