import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { LciaScore, FetchLciaScoresParams } from './types';

const fetchLciaScores = async (params: FetchLciaScoresParams) => {
  const { data } = await api.get(`/spold/lcia_results`, { params });
  return data;
};

const useLciaScores = (dataset_id: number, method_id: number) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<LciaScore[], AxiosError>(
    ['lciaData', dataset_id, version, system_model, method_id],
    () => fetchLciaScores({ dataset_id, method_id, version, system_model }),
    { enabled: !!dataset_id && dataset_id !== -1 }
  );
};

export default useLciaScores;
