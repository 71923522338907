import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { FetchDatasetParams } from '../types';

import { ConsumingActivitiesResponse } from './types';

const fetchConsumingActivities = async (params: FetchDatasetParams) => {
  const { data } = await api.get(`/spold/consuming_activities`, { params });

  return data;
};

const useConsumingActivities = (id?: string) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<ConsumingActivitiesResponse, AxiosError>(
    ['consumingActivities', id, version, system_model],
    () => fetchConsumingActivities({ dataset_id: id as string, version, system_model }),
    { enabled: !!id }
  );
};

export default useConsumingActivities;
