import React from 'react';

import { TableContainer, Table, Thead, Tr, Th, Tbody } from '@ecoinvent/ui';

import LciResultRow from './LciResultRow';
import { FilteredExchange } from './types';

type Props = { exchanges: FilteredExchange[] };

const LciResultTable = ({ exchanges }: Props) => {
  return (
    <TableContainer maxHeight={'500px'} height={'100%'} overflowY={'auto'} whiteSpace={'break-spaces'}>
      <Table size={'sm'}>
        <Thead>
          <Tr>
            <Th>Exchange Name</Th>
            <Th>Compartment</Th>
            <Th>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {exchanges.map((item, key) => {
            return <LciResultRow item={item} key={key} />;
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default LciResultTable;
