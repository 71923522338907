import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import useVersionInfo from '../../useVersionInfo';

import { ExportFile, FetchDatasetParams } from './types';

const fetchExportFileList = async (params: FetchDatasetParams) => {
  const { data } = await api.get(`/spold/export_file_list`, { params });
  return data;
};

const useExportFileList = (id?: string) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<ExportFile[], AxiosError>(
    ['exportFile', id, version, system_model],
    () => fetchExportFileList({ dataset_id: id as string, version, system_model }),
    { enabled: !!id, retry: false }
  );
};

export default useExportFileList;
