import React from 'react';

import { Alert, AlertDescription, AlertIcon, Button, Link, Stack } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useAuth } from 'react-oidc-context';

import useAuthUser from 'hooks/useAuthUser';
import useVersionInfo from 'hooks/useVersionInfo';
import Documentation from 'pages/Documentation';
import { appConfig } from 'utilities/env';

type Props = { datasetId: string; isLoading?: boolean };

const PublicDatasetView = ({ datasetId, isLoading }: Props) => {
  const auth = useAuth();
  const { data: authUser, isLoading: authLoading } = useAuthUser();
  const { ver, system_model } = useVersionInfo();
  const upgradeUrl = appConfig.accountsUrl;

  const renderAlert = () => {
    if (isLoading || authLoading) return null;

    if (auth.isAuthenticated && authUser?.agreement_accepted) {
      return (
        <Alert status="warning" variant={'left-accent'} borderRadius={'md'}>
          <AlertIcon />
          <AlertDescription>
            {ver} {system_model} is not included in your licence,{' '}
            <Button colorScheme="orange" variant={'link'} as={Link} href={upgradeUrl}>
              upgrade now
            </Button>{' '}
            to access the full dataset.
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <Alert status="info" variant={'left-accent'} borderRadius={'md'}>
        <AlertIcon />
        <AlertDescription>
          To access the full dataset, please{' '}
          <Button
            variant="link"
            colorScheme="blue"
            onClick={() => {
              mixpanel.track('Login (Dataset)', { datasetId: datasetId });
              auth.signinRedirect({ redirect_uri: window.location.href });
            }}
          >
            sign in.
          </Button>
        </AlertDescription>
      </Alert>
    );
  };

  return (
    <Stack spacing={5}>
      {renderAlert()}
      <Documentation />
    </Stack>
  );
};

export default PublicDatasetView;
