import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { MOST_RECENT_VERSION } from 'utilities/constants/versions';

import { VersionInfoParams } from './types';

const useVersionInfo = () => {
  const { ver = MOST_RECENT_VERSION, system_model = 'cutoff' } = useParams<VersionInfoParams>();

  return useMemo(
    () => ({
      ver,
      system_model,
    }),
    [ver, system_model]
  );
};

export default useVersionInfo;
