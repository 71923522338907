import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import api from 'api/api';

import { RecentDataset } from './types';

const fetchRecentDatasets = async () => {
  const { data } = await api.get(`/web/recent_datasets`);

  return data;
};

const useRecentDatasets = () => {
  const auth = useAuth();
  return useQuery<RecentDataset[], AxiosError>('recentDatasets', fetchRecentDatasets, {
    enabled: auth.isAuthenticated,
  });
};

export default useRecentDatasets;
