import { Alert, AlertDescription, AlertIcon, Link } from '@ecoinvent/ui';

import { appConfig } from 'utilities/env';

const ConsentBanner = () => {
  return (
    <Alert status="warning">
      <AlertIcon />
      <AlertDescription fontSize={'sm'}>
        We've updated our Data Privacy agreements. To continue using ecoQuery,{' '}
        <Link colorScheme="orange" href={`${appConfig.accountsUrl}/consent?redirectUrl=${window.location.href}`}>
          please accept the updated agreements.
        </Link>
      </AlertDescription>
    </Alert>
  );
};

export default ConsentBanner;
