import { Text, DetailListConfig } from '@ecoinvent/ui';

import { FileAttributes } from 'hooks/data/datasets/documentation/types';
import { convertISOToDate } from 'utilities/helpers/formats';

const fileAttributesConfig: DetailListConfig<FileAttributes> = [
  {
    label: 'Creation date',
    key: 'creation_timestamp',
    renderItem: (date) => <Text>{date && convertISOToDate(date)}</Text>,
  },
  {
    label: 'Last edit',
    key: 'file_timestamp',
    renderItem: (date) => <Text>{date && convertISOToDate(date)}</Text>,
  },
  {
    label: 'Major Release',
    key: 'major_release',
  },
  {
    label: 'Minor Release',
    key: 'minor_release',
  },
  {
    label: 'Major Revision',
    key: 'major_revision',
  },
  {
    label: 'Minor Revision',
    key: 'minor_revision',
  },
];

export default fileAttributesConfig;
