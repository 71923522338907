import { Heading, Link, Stack } from '@ecoinvent/ui';

import PageHead from 'components/shared/PageHead';
import DocumentationMarkdownRenderer from 'pages/Documentation/components/DocumentationMarkdownRenderer';

import ActivitySearch from './components/ActivitySearch';

const homeMarkdown = `
Follow [our step-by-step guide](https://support.ecoinvent.org/getting-started#step-by-step-guide) for more information on how to use ecoQuery, search for datasets and access all available documentation, files, etc. 

To leverage the ecoinvent database to the fullest we recommend our users to go through the Fundamentals of the ecoinvent Database [e-learning course](https://support.ecoinvent.org/e-learning-fundamentals-database) as well as consult the [ecoinvent knowledge base](https://support.ecoinvent.org/introduction-to-the-database), including the explanation of system models. Our [Glossary](https://support.ecoinvent.org/glossary) and [FAQs](https://support.ecoinvent.org/faq) clarify the terms we use, as well as answering common questions for users.`;

const Search = () => {
  return (
    <Stack
      width="90%"
      maxWidth={'1500px'}
      alignSelf={'center'}
      spacing={5}
      px={{ xs: '1rem', md: '2rem' }}
      justifyContent="space-between"
    >
      <Heading as="h2" size="2xl">
        Database Search
      </Heading>
      <PageHead title="Home" />
      <DocumentationMarkdownRenderer
        components={{
          a: (props) => <Link {...props} isExternal fontWeight={'semibold'} />,
        }}
      >
        {homeMarkdown}
      </DocumentationMarkdownRenderer>

      <ActivitySearch />
    </Stack>
  );
};

export default Search;
