import { Badge, Button, HStack, Icon, Link, Stack, Text, Tooltip, useColorModeValue } from '@ecoinvent/ui';
import { BiChevronRight } from 'react-icons/bi';
import { FiInfo } from 'react-icons/fi';

import { ReleaseVersionMap } from 'hooks/data/versions/types';
import { PROBLEMATIC_VERSIONS } from 'utilities/constants/versions';

import { systemModelAlerts, systemModelSecondaryText, system_model_long_name } from './data';
import SystemModelDetails from './SystemModelDetails';

type Props = {
  releaseVersion: string;
  all_releases: ReleaseVersionMap;
  selectSystemModel: (selectedModel: string) => void;
};

const SystemDialogContent = ({ releaseVersion, all_releases, selectSystemModel }: Props) => {
  const shortNameColor = useColorModeValue('gray.600', 'gray.300');
  const hoverBgColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');

  const handleOnClick = (system_model: string) => {
    selectSystemModel(system_model);
  };
  return (
    <Stack spacing={8}>
      {PROBLEMATIC_VERSIONS.includes(releaseVersion) ? systemModelAlerts[releaseVersion] : systemModelAlerts['info']}
      <Stack data-test={'select-system-model-list'} spacing={4}>
        {all_releases[releaseVersion].map((v) => {
          const details = systemModelSecondaryText[system_model_long_name[v.system_model]];
          if (v.system_model === 'undefined') {
            return;
          }
          return (
            <Stack
              key={v.system_model}
              rounded="md"
              py={1}
              w="100%"
              _hover={{ bg: hoverBgColor }}
              aria-roledescription="release version"
              cursor="pointer"
              onClick={() => handleOnClick(v.system_model)}
            >
              <HStack justify="space-between">
                <Stack spacing={0}>
                  <HStack>
                    <Text fontWeight="semibold" fontFamily={'heading'}>
                      {system_model_long_name[v.system_model]}{' '}
                      <Text as="span" fontSize={'sm'} color={shortNameColor}>
                        ({v.system_model})
                      </Text>
                    </Text>
                    {v.system_model.includes('cutoff') && (
                      <Badge colorScheme="green" variant="solid">
                        MOST POPULAR
                      </Badge>
                    )}
                  </HStack>
                  {details && <SystemModelDetails details={details} />}
                </Stack>
                <Icon as={BiChevronRight} fontSize="2xl" color="gray.500" />
              </HStack>
            </Stack>
          );
        })}
        <HStack>
          {all_releases[releaseVersion]
            .filter((v) => v.system_model === 'undefined')
            .map((v) => (
              <Button
                rightIcon={
                  <Tooltip
                    label={
                      <Stack justifyContent="center" textAlign="center" spacing={0}>
                        <Text color="white">
                          Undefined unit processes are unlinked and multi-output, meaning no suppliers are determined and no
                          allocation is applied.
                        </Text>
                        <Link color="green.300" isExternal href="https://support.ecoinvent.org/upr-lci-lcia">
                          Learn more
                        </Link>
                      </Stack>
                    }
                    hasArrow
                    closeDelay={2000}
                    placement="top"
                    bg="gray.900"
                    py={2}
                    pointerEvents={'all'}
                    borderRadius="md"
                    width="100%"
                  >
                    <Text as="span" display="flex" justifyContent={'flex-end'}>
                      <Icon as={FiInfo} fontSize="xl" />
                    </Text>
                  </Tooltip>
                }
                key={v.system_model}
                py={1}
                onClick={() => handleOnClick(v.system_model)}
                variant="link"
              >
                Use undefined (unlinked, multi-output) datasets
              </Button>
            ))}
        </HStack>
      </Stack>
    </Stack>
  );
};

export default SystemDialogContent;
