import { ExchangeFilterCheckbox } from './types';

export const baseCheckboxes: ExchangeFilterCheckbox[] = [
  {
    name: 'Comments',
    key: 'comment',
  },
  {
    name: 'Uncertainties',
    key: 'uncertainty',
  },
];

export const elementaryCheckboxes: ExchangeFilterCheckbox[] = [
  ...baseCheckboxes,
  {
    name: 'Inputs from environment',
    key: 'From environment',
  },
  {
    name: 'Outputs to environment',
    key: 'To environment',
  },
];
