import React from 'react';

import { Heading, Stack, Text } from '@ecoinvent/ui';
import { Link } from 'react-router-dom';

import useVersionInfo from 'hooks/useVersionInfo';

const DatasetNotFound = () => {
  const { ver, system_model } = useVersionInfo();
  return (
    <Stack py={4} spacing={8} alignItems="center">
      <Heading as="h1" size="4xl">
        Oh No...
      </Heading>
      <Text>The dataset you're looking for couldn't be found.</Text>

      <Stack width="100%">
        <Text fontWeight={'medium'} fontSize={'lg'}>
          Things you can try:
        </Text>
        <Stack as={'ul'}>
          <Text as={'li'}>Go Back</Text>
          <Text as={'li'}>
            Start a <Link to={`/${ver}/${system_model}/search`}>new search</Link>
          </Text>
          <Text as={'li'}>
            Try changing the version or system model (currently {ver} {system_model})
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DatasetNotFound;
