import React from 'react';

import { Box, Grid, GridItem, Skeleton } from '@ecoinvent/ui';

import useRelatedDatasets from 'hooks/data/datasets/useRelatedDatasets';
import useVersionInfo from 'hooks/useVersionInfo';
import RelatedDropdown from 'pages/Dataset/components/RelatedDatasets/RelatedDropdown';

import { buildDropdownData } from './dropdownData';

type Props = {
  url_path: string;
  id: string;
};

const RelatedDatasets = ({ url_path, id }: Props) => {
  const { data: relatedData, isLoading } = useRelatedDatasets(id);
  const { system_model } = useVersionInfo();

  const dropdownData = buildDropdownData(relatedData);
  const newDropdownData =
    system_model === 'undefined'
      ? dropdownData.filter(({ label }) => ['Version', 'System model', 'Geography', 'Activity name'].includes(label))
      : dropdownData;

  return (
    <Box>
      <Grid
        gap={2}
        mt={{ xs: '1rem', sm: 0 }}
        templateColumns={{ lg: system_model !== 'undefined' ? '1fr 2fr 4fr 1fr 2fr' : '1fr 2fr 4fr 1fr' }}
      >
        {newDropdownData.map(({ label, options, gridProps }) => (
          <GridItem key={label} {...gridProps}>
            <Skeleton isLoaded={!isLoading}>
              <RelatedDropdown options={options} label={label} url_path={url_path} />
            </Skeleton>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
export default RelatedDatasets;
