import React from 'react';

import { Box } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';

import AutoCompleteSearch from 'components/shared/AutoCompleteSearch';
import useSearchFilters from 'hooks/data/search/useSearchFilters';

const ActivitySearchBar = () => {
  const {
    filterValues: { query, ...filters },
    setBulkFilterValues,
  } = useSearchFilters();

  const onSearch = (value: string) => {
    setBulkFilterValues({ query: value, currentPage: 1 });
    value && mixpanel.track('Search', { query: value, filters });
  };

  return (
    <Box maxW={'100%'} minW="300px">
      <AutoCompleteSearch onSearch={onSearch} defaultValue={query ?? ''} filters={filters} />
    </Box>
  );
};

export default ActivitySearchBar;
