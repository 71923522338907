import mixpanel from 'mixpanel-browser';

export const setupAnalyticsIfAllowed = () => {
  initMixpanel();
  if (!window.Cookiebot.consent.statistics) return;

  mixpanel.opt_in_tracking();
};

export const disableAnalytics = () => {
  mixpanel.opt_out_tracking();
};

export const initMixpanel = () =>
  mixpanel.init('6c631aa394b4310fa9e23c8955e732dd', {
    debug: !import.meta.env.PROD,
    opt_out_tracking_by_default: true,
  }); // TODO: remove the this hack once the type is updated
