import React from 'react';

import { Stack } from '@ecoinvent/ui';
import { useAuth } from 'react-oidc-context';

import NavBar from 'components/shared/NavBar';
import useAuthUser from 'hooks/useAuthUser';

import ConsentBanner from '../ConsentBanner';
import YearReviewBanner from '../YearReviewBanner';

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();
  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();

  const shouldShowConsentBanner = !isLoading && !isAuthLoading && isAuthenticated && !authUser?.agreement_accepted;

  return (
    <Stack width={'100%'} spacing={0}>
      <NavBar />
      <YearReviewBanner />
      {shouldShowConsentBanner && <ConsentBanner />}
      <Stack data-testid="page-container" py={10}>
        {children}
      </Stack>
    </Stack>
  );
};

export default Layout;
