import { AxiosError } from 'axios';
import { QueryClientConfig, QueryOptions } from 'react-query';

const shouldRetry = (failureCount: number, error: AxiosError) => {
  const status = Number(error.response?.status ?? 0);
  return status >= 400 && status < 500 ? false : failureCount < 3;
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: import.meta.env.DEV,
      retry: shouldRetry as QueryOptions<AxiosError>['retry'],
    },
  },
};
