import { useState } from 'react';

import {
  Button,
  DarkMode,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useColorModeValue,
} from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiArrowBack, BiChevronDown } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';

import useRelatedDatasets from 'hooks/data/datasets/useRelatedDatasets';
import useSearchFilters from 'hooks/data/search/useSearchFilters';
import { ReleaseVersionMap } from 'hooks/data/versions/types';
import useModal from 'hooks/useModal';
import useVersionInfo from 'hooks/useVersionInfo';
import { computeNewUrlWithVersions } from 'utilities/routing';

import ReleaseDialogContent from './ReleaseDialogContent';
import SystemDialogContent from './SystemDialogContent';

type Props = {
  all_releases: ReleaseVersionMap;
};

const steps = [{ title: 'Version' }, { title: 'System Model' }];

const ModalSelector = ({ all_releases }: Props) => {
  const { spold_id } = useParams();
  const { data } = useRelatedDatasets(spold_id);
  const [releaseVersion, setReleaseVersion] = useState('-1');
  const { isOpen, onOpen, onClose } = useModal();
  const { setFilterValue, filterValues } = useSearchFilters();
  const buttonColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');

  const { ver, system_model } = useVersionInfo();
  const navigate = useNavigate();

  const buttonTitle = `${ver} - ${system_model}`;

  const handleCloseClick = () => {
    setReleaseVersion('-1');
    onClose();
  };

  const selectSystemModel = (selectedModel: string) => {
    // Preserve query without filter values when computing new versions

    // Check if there are related datasets, if not set the next spold_id to undefined
    const nextSpoldId =
      data?.filter((entry) => entry.system_model === selectedModel) &&
      data?.filter((entry) => entry.system_model === selectedModel).length > 0
        ? data?.filter((entry) => entry.system_model === selectedModel)[0].spold_id
        : undefined;

    const newVersion = computeNewUrlWithVersions(
      { ver, system_model },
      { ver: releaseVersion, system_model: selectedModel },
      filterValues.query,
      nextSpoldId?.toString(),
      spold_id
    );

    if (parseFloat(releaseVersion) < 3.8) {
      setFilterValue('sector', []);
    }

    mixpanel.track('Select Version', { systemModel: selectedModel, version: releaseVersion });

    navigate(newVersion);

    handleCloseClick();
  };

  const hasSelectedRelease = releaseVersion !== '-1';
  return (
    <>
      <DarkMode>
        <Button
          size="sm"
          bg={buttonColor}
          border={'1px solid'}
          variant={'subtle'}
          color={'white'}
          borderColor={'whiteAlpha.300'}
          onClick={onOpen}
          data-test={'switch-version-button'}
          rightIcon={<Icon as={BiChevronDown} />}
        >
          {buttonTitle}
        </Button>
      </DarkMode>

      <Modal onClose={handleCloseClick} isOpen={isOpen} size="2xl">
        <ModalOverlay />
        <ModalContent p={8} as={Stack} spacing={5}>
          <ModalHeader p={0}>
            <HStack justify={'space-between'}>
              <Stack direction={'row'} spacing={2} alignItems="center">
                {hasSelectedRelease && <Icon as={BiArrowBack} onClick={() => setReleaseVersion('-1')} />}
                <Heading size="md" alignItems="center">
                  Change Version & System Model
                </Heading>
              </Stack>
              <ModalCloseButton top={6} right={5} />
            </HStack>
          </ModalHeader>

          <ModalBody p={0} as={Stack} spacing={5}>
            <Stepper index={releaseVersion === '-1' ? 0 : 1} colorScheme="green">
              {steps.map((step, index) => (
                <Step key={index} as={HStack}>
                  <StepIndicator>
                    <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                  </StepIndicator>
                  <StepTitle as={Text} mb={0} fontFamily={'heading'} fontWeight={'semibold'}>
                    {step.title}
                  </StepTitle>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {!hasSelectedRelease ? (
              <ReleaseDialogContent all_releases={all_releases} setReleaseVersion={setReleaseVersion} />
            ) : (
              <SystemDialogContent
                releaseVersion={releaseVersion}
                all_releases={all_releases}
                selectSystemModel={selectSystemModel}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalSelector;
