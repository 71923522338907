import React, { useCallback, useEffect, useRef } from 'react';

import {
  Icon,
  Kbd,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import AutoCompleteSearch from 'components/shared/AutoCompleteSearch';
import useVersionInfo from 'hooks/useVersionInfo';

const QuickSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { ver, system_model } = useVersionInfo();
  const navigate = useNavigate();

  const onSearch = (value: string) => {
    if (!value) return;
    mixpanel.track('Quick Search', { query: value });
    navigate(`/${ver}/${system_model}/search?query=${value}`);
    onClose();
  };

  const keyPressed = useRef<Record<string, boolean>>({});

  const handleKeyDown = useCallback<EventListener>(
    (event: any) => {
      // first ctrl press, stop chrome shortcut propagation
      if (event.key === 'Control' || event.key === 'Meta') {
        event.preventDefault();
      }

      keyPressed.current[event.key] = true;

      // ctrl + k
      if ((keyPressed.current['Meta'] || keyPressed.current['Control']) && event.key === 'k') {
        event.preventDefault();
        onOpen();
      }
    },
    [keyPressed, onOpen]
  );
  const handleKeyUp = useCallback<EventListener>(
    (event: any) => {
      delete keyPressed.current[event.key];
    },
    [keyPressed]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const display = useBreakpointValue({ base: 'none', xs: 'none', lg: 'flex' });

  const barSize = useBreakpointValue({ base: 'xs', xs: 'sm', md: 'lg', lg: 'xl', xl: '5xl' });
  const emptyFilters = { activity_type: [], geography: [], isic_class: [], isic_section: [], sector: [] };

  const bg = useColorModeValue('white', 'gray.700');
  const searchColor = useColorModeValue('green.500', 'green.300');

  return (
    <Stack display={display}>
      <Stack
        borderRadius={'md'}
        p={2}
        spacing={1}
        direction="row"
        alignItems={'center'}
        role="button"
        onClick={onOpen}
        bg={bg}
        cursor={'pointer'}
      >
        <Stack spacing={'3px'} width="120px" direction="row" alignItems={'center'}>
          <Icon as={BiSearch} color={searchColor} />
          <Text fontWeight={'semibold'} color={searchColor} fontSize="small">
            Quick Search
          </Text>
        </Stack>
        <Kbd>{navigator.appVersion.indexOf('Mac') != -1 ? '⌘' : 'Ctrl'} + K</Kbd>
      </Stack>
      <Modal size={barSize} isOpen={isOpen} onClose={onClose} closeOnEsc>
        <ModalOverlay />
        <ModalContent>
          <Stack>
            <AutoCompleteSearch size={'lg'} filters={emptyFilters} defaultValue={''} onSearch={onSearch} />
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default QuickSearch;
