import React from 'react';

import { HStack, Icon, IconButton, IconButtonProps, useToast, Text, Stack, useColorModeValue } from '@ecoinvent/ui';
import { FiCheck, FiCopy } from 'react-icons/fi';

type Props = Omit<IconButtonProps, 'aria-label' | 'icon'> & {
  value: string | number;
  onValueCopied?: (value: string | number) => void;
};

const CopyToClipboardButton = ({ value, onValueCopied, ...props }: Props) => {
  const [hasCopied, setHasCopied] = React.useState(false);
  const toast = useToast();

  const toastBg = useColorModeValue('gray.800', 'gray.900');

  const onCopyClick = () => {
    navigator.clipboard.writeText(value.toString());
    setHasCopied(true);
    onValueCopied?.(value);
    toast({
      render: () => (
        <Stack>
          <HStack alignSelf="center" bg={toastBg} border={'1px solid'} borderColor={'gray.700'} py={1} px={2} borderRadius="md">
            <Text color="white">Value copied to clipboard</Text>
            <Text>✨</Text>
          </HStack>
        </Stack>
      ),
      duration: 2000,
    });

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <IconButton
      colorScheme="gray"
      aria-label={'copy to clipboard'}
      size="sm"
      icon={<Icon as={hasCopied ? FiCheck : FiCopy} />}
      onClick={onCopyClick}
      {...props}
    />
  );
};

export default CopyToClipboardButton;
