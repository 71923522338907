import { Icon, Text, HStack, useColorModeValue, StackProps } from '@ecoinvent/ui';
import { LuSearchX } from 'react-icons/lu';

type Props = StackProps & {
  children: React.ReactNode;
};

const NoResultCard = ({ children, ...rest }: Props) => {
  const color = useColorModeValue('gray.500', 'gray.400');

  return (
    <HStack p={4} w="100%" whiteSpace={'nowrap'} color={color} {...rest}>
      <Icon as={LuSearchX} fontSize={'lg'} />
      <Text> {children}</Text>
    </HStack>
  );
};

export default NoResultCard;
