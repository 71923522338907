import axios from 'axios';
import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import { appConfig } from 'utilities/env';

export type KcUser = User['profile'];

const fetchUserInfo = async (user: User) => {
  const { data } = await axios.get(`${appConfig.kcAuthority}/protocol/openid-connect/userinfo`, {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  });
  return data;
};

const useAuthUser = () => {
  const { user, isLoading } = useAuth();

  return useQuery<KcUser, Error>('authProfile', () => fetchUserInfo(user as User), {
    enabled: !isLoading && !!user,
  });
};

export default useAuthUser;
