import React from 'react';

import { Table, TableContainer, Td, Text, Tbody, Tr, Stack, Thead, Th, Heading } from '@ecoinvent/ui';

import { ExchangeUncertainty } from 'hooks/data/datasets/exchanges/types';
import { humanizeCamelCase } from 'utilities/helpers/formats';

type Props = { data: ExchangeUncertainty };

const Uncertainty = ({ data }: Props) => {
  if (!data?.type) return null;

  const uncertaintyKeys = Object.keys(data?.value) as Array<keyof ExchangeUncertainty['value']>;

  return (
    <Stack>
      <Heading as={'h4'} fontSize={'xl'} fontWeight={'semibold'}>
        Uncertainty
      </Heading>
      {data?.comment && <Text>{data?.comment}</Text>}
      <TableContainer>
        <Table size="small" aria-label="exchange uncertainty values">
          <Thead>
            <Tr>
              <Th w={'100px'}>
                <b>Type</b>
              </Th>
              <Th>{data?.type}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {uncertaintyKeys.map((key_string, idx) => {
              return (
                <Tr key={idx} fontSize={'sm'}>
                  <Td scope="row">
                    <b>{humanizeCamelCase(key_string)} </b>
                  </Td>
                  <Td scope="row">{data?.value[key_string]}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Uncertainty;
