import { Link, Text, HStack, Stack, Icon, useColorModeValue } from '@ecoinvent/ui';
import { format } from 'date-fns';
import { BiChevronRight } from 'react-icons/bi';
import { TiWarningOutline } from 'react-icons/ti';

import { ReleaseVersionMap } from 'hooks/data/versions/types';
import { PROBLEMATIC_VERSIONS } from 'utilities/constants/versions';

type Props = {
  all_releases: ReleaseVersionMap;
  setReleaseVersion: (version: string) => void;
};

const ReleaseDialogContent = ({ all_releases, setReleaseVersion }: Props) => {
  const hoverColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.100');
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');
  return (
    <Stack spacing={8}>
      <Text>
        A new version of the ecoinvent database is published every year, with expanded sectorial and geographical coverage. —
        for more information, please visit our documentation on the{' '}
        <Link
          href={'https://ecoinvent.org/the-ecoinvent-database/data-releases'}
          target="_blank"
          rel="noopener noreferrer"
          title="Release documentation on ecoinvent website"
          fontWeight={'semibold'}
          colorScheme="green"
        >
          ecoinvent website
        </Link>
        .
      </Text>

      <Stack as="nav" data-test={'select-database-version-list'} spacing={4}>
        {Object.keys(all_releases).map((version, idx) => {
          return (
            <Stack
              py={1}
              w="100%"
              _hover={{ bg: hoverColor }}
              key={idx}
              aria-roledescription="release version"
              cursor={'pointer'}
              onClick={() => setReleaseVersion(version)}
              rounded="md"
            >
              <HStack justify={'space-between'} align={'center'}>
                <Stack spacing={0}>
                  <Text fontWeight={'semibold'} fontFamily={'heading'}>
                    Version {version}{' '}
                  </Text>
                  <Text fontSize={'sm'} color={grayTextColor}>
                    {all_releases[version].length > 0 ? format(new Date(all_releases[version][0]?.release_date), 'PPP') : ''}
                  </Text>
                </Stack>
                <HStack>
                  {PROBLEMATIC_VERSIONS.includes(version) && <Icon as={TiWarningOutline} color="yellow.600" fontSize="2xl" />}
                  <Icon as={BiChevronRight} fontSize={'2xl'} color="gray.500" />
                </HStack>
              </HStack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ReleaseDialogContent;
