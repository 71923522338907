import React from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { disableAnalytics, initMixpanel, setupAnalyticsIfAllowed } from 'utilities/consent/cookies';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './fonts.css';

const disableReactDevTools = () => {
  const noop = () => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

if (import.meta.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

['production'].includes(import.meta.env.MODE) &&
  Sentry.init({
    dsn: 'https://2061d2bf14ea349e6201ef9828c7705c@o4505124399546368.ingest.us.sentry.io/4507542513975296',
    tracesSampleRate: 1,
    environment: window._ENV_?.NODE_ENV ?? import.meta.env.MODE,
    tracePropagationTargets: [
      'https://api.ecoquery.ecoinvent.org/',
      'https://accounts.ecoinvent.org',
      'https://ecoquery.ecoinvent.org/',
    ],
    replaysOnErrorSampleRate: 0.25,
  });

window.addEventListener('CookiebotOnAccept', setupAnalyticsIfAllowed);
window.addEventListener('CookiebotOnDecline', disableAnalytics);

const container = document.getElementById('root');

const root = createRoot(container as HTMLElement);

initMixpanel();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
