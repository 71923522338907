import { Stack, Tbody, Table, TableContainer, Td, Th, Thead, Tr, Link } from '@ecoinvent/ui';

import { DataEntryBy } from 'hooks/data/datasets/documentation/types';

type Props = { details: DataEntryBy };

const DataEntryByTable = ({ details }: Props) => {
  const { person_name, person_email, is_active_author } = details;

  const correctedPersonName = person_name === '[System]' ? 'ecoinvent' : person_name;

  return (
    <TableContainer boxShadow={'xs'} as={Stack} whiteSpace={'break-spaces'} borderRadius={'md'} w="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th isNumeric>Currently Active</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{correctedPersonName}</Td>
            <Td>{<Link href={`mailto:${person_email}`}>{person_email}</Link>}</Td>
            <Td isNumeric>{is_active_author ? 'Yes' : 'No'}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default DataEntryByTable;
