import React from 'react';

import { Stack, UseToastOptions, HStack, Text, Spinner } from '@ecoinvent/ui';

const LoadingToast: UseToastOptions['render'] = ({ description }) => {
  return (
    <Stack py={2} px={4} bg={'blackAlpha.800'} color="white" borderRadius={'md'} boxShadow={'sm'}>
      <HStack>
        <Stack>
          <Text fontSize={'sm'}>{description}</Text>
        </Stack>
        <Spinner size="xs" />
      </HStack>
    </Stack>
  );
};

export default LoadingToast;
