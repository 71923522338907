import { useMemo, useState } from 'react';

import { Stack, Tab, TabList, Tabs, Checkbox, HStack, TabPanels, TabPanel, Skeleton } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import { ElementaryExchange, IntermediateExchange } from 'hooks/data/datasets/exchanges/types';
import useVersionInfo from 'hooks/useVersionInfo';

import { baseCheckboxes, elementaryCheckboxes } from './checkboxes';
import ExchangeInput from './ExchangeInput';
import ExchangeList from './ExchangeList';
import { ExchangeFilter } from './types';

type Props = {
  intermediateExchange: IntermediateExchange[];
  elementaryExchange: ElementaryExchange[];
  isLoading?: boolean;
};

const ExchangeView = ({ intermediateExchange, isLoading, elementaryExchange }: Props) => {
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const { spold_id } = useParams();

  const [enabledFeatures, setEnabledFeatures] = useState<ExchangeFilter[]>(['To environment', 'From environment']);

  const { ver, system_model } = useVersionInfo();
  const baseUrl = `/${ver}/${system_model}/dataset/`;

  const onChangeTab = (newValue: number) => {
    if ([0, 1].includes(activeTab)) setActiveTab(newValue);
  };

  const handleToggle = (value: ExchangeFilter) => () => {
    const currentIndex = enabledFeatures.indexOf(value);
    const newChecked = [...enabledFeatures];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setEnabledFeatures(newChecked);
  };

  const filteredExchanges = useMemo(() => {
    const exchanges = activeTab === 0 ? intermediateExchange : elementaryExchange;
    const key = `${ver}_${system_model}_${spold_id}`;

    const filtered = exchanges
      .map((exc, idx) => ({ ...exc, key: `${key}_${exc.group.replaceAll(' ', '_')}_${idx}` }))
      .filter(
        (exc: any) =>
          exc.name.toLowerCase().includes(searchText.toLowerCase()) ||
          exc.link?.name.toLowerCase().includes(searchText.toLowerCase()) ||
          exc.link?.geography.toLowerCase().includes(searchText.toLowerCase())
      )
      .filter((exc) => (activeTab === 0 ? true : enabledFeatures.includes(exc.group as ExchangeFilter)));

    return filtered;
  }, [activeTab, elementaryExchange, intermediateExchange, searchText, enabledFeatures, spold_id, system_model, ver]);

  const checkboxesToRender = activeTab > 0 ? elementaryCheckboxes : baseCheckboxes;

  const elementaryExchangesTabLabel = `${
    filteredExchanges.length < elementaryExchange.length && activeTab !== 0 ? `${filteredExchanges.length} of ` : ''
  }${elementaryExchange.length}`;

  return (
    <Stack direction={'row'} spacing={6}>
      <Stack w={'100%'}>
        <Stack direction={{ base: 'column', lg: 'row' }} alignItems={{ lg: 'center' }} justify={'space-between'}>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <Stack maxW={'400px'} w={'100%'}>
              <ExchangeInput
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Filter exchanges..."
              />
            </Stack>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <HStack>
              {checkboxesToRender.map((checkbox) => (
                <Checkbox
                  key={checkbox.key}
                  size={'sm'}
                  onChange={handleToggle(checkbox.key)}
                  isChecked={enabledFeatures.includes(checkbox.key)}
                >
                  {checkbox.name}
                </Checkbox>
              ))}
            </HStack>
          </Skeleton>
        </Stack>
        <Tabs index={activeTab} onChange={onChangeTab} w={'100%'}>
          <Skeleton isLoaded={!isLoading}>
            <TabList>
              <Tab fontSize={'sm'}>Intermediate ({intermediateExchange.length})</Tab>
              <Tab fontSize={'sm'}>Elementary ({elementaryExchangesTabLabel})</Tab>
            </TabList>
          </Skeleton>

          <TabPanels>
            <TabPanel p={0}>
              <ExchangeList
                exchanges={filteredExchanges}
                enabledFeatures={enabledFeatures}
                baseUrl={baseUrl}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel p={0}>
              <ExchangeList
                exchanges={filteredExchanges}
                enabledFeatures={enabledFeatures}
                baseUrl={baseUrl}
                isLoading={isLoading}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default ExchangeView;
