export type FileAccessResponse = {
  has_access: boolean;
};

export type FileResponse = {
  version_name: string;
  version_files: File[];
  releases: ReleaseFiles[];
};

export type DownloadFileResponse = {
  download_url: string;
};

export type ReleaseFiles = {
  system_model_name: string;
  release_files: File[];
};

export enum ReportVersion {
  V2 = 'v2',
  V3 = 'v3',
}

export type File = {
  last_modified: string;
  name: string;
  size: number;
  uuid: string;
  description: string | null;
  version?: ReportVersion;
};
