import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { FetchDatasetParams } from '../types';

import { DocumentationResponse } from './types';

const fetchDatasetDocumentation = async (params: FetchDatasetParams) => {
  const { data } = await api.get(`/spold/documentation`, { params });

  return data;
};

const useDatasetDocumentation = (id?: string) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<DocumentationResponse, AxiosError>(
    ['documentation', id, version, system_model],
    () => fetchDatasetDocumentation({ dataset_id: id as string, version, system_model }),
    { enabled: !!id, retry: false }
  );
};

export default useDatasetDocumentation;
