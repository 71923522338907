import React from 'react';

import {
  Link,
  Skeleton,
  Table,
  Tbody,
  Td,
  TableContainer,
  Th,
  Tr,
  Thead,
  Stack,
  VisuallyHidden,
  useColorModeValue,
} from '@ecoinvent/ui';
import { Link as RouterLink } from 'react-router-dom';

import NoResultCard from 'components/UI/NoResultCard';
import { SpoldResult } from 'hooks/data/search/types';
import { capitalizeFirstLetter } from 'utilities/helpers/formats';

type Props = {
  version: string;
  isLoading?: boolean;
  system_model: string;
  data: SpoldResult[];
  totalHits: number;
  onResultClick: (spold_id: string) => void;
};

const activityTypeToDisplayName = (activityType: SpoldResult['_source']['activity_type']) => {
  return activityType.split('_').join(' ').toLowerCase();
};

const ActivitySearchResultTable = ({ data, isLoading, version, system_model, onResultClick }: Props) => {
  const renderSkeleton = () => {
    return (
      <>
        {new Array(5).fill(0).map((_, row) => {
          return (
            <Tr key={`skeleton_row_${row}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {new Array(7).fill(0).map((_, col) => (
                <Td key={`skeleton_td_${row}_${col}`}>
                  <Skeleton height={'18px'} />
                </Td>
              ))}
            </Tr>
          );
        })}
      </>
    );
  };

  const borderColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');
  const isSystemModelDefined = system_model !== 'undefined';
  return (
    <TableContainer
      as={Stack}
      whiteSpace={'break-all'}
      border={'1px solid'}
      borderColor={borderColor}
      borderRadius={'md'}
      w="100%"
      overflowX={'auto'}
    >
      <Table fontSize={'sm'} w="100%" aria-label="simple table">
        <Thead>
          <Tr>
            <Th>Activity name</Th>
            {isSystemModelDefined && <Th>Reference product</Th>}
            {isSystemModelDefined && <Th>Unit</Th>}
            <Th>Geography</Th>
            <Th>Sector</Th>
            <Th>Activity type</Th>
            <Th>
              <VisuallyHidden>Details</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody data-testid="search-result-table">
          {isLoading ? (
            renderSkeleton()
          ) : !data.length ? (
            <NoResultCard>No Results Found</NoResultCard>
          ) : (
            data.map((row) => (
              <Tr key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <Td data-testid="activity-name">{row._source.activity_name}</Td>
                {isSystemModelDefined && <Td data-testid="product-name">{row._source.product_name}</Td>}
                {isSystemModelDefined && <Td data-testid="unit-name">{row._source.unit_name}</Td>}
                <Td data-testid="geography">{`${row._source.geography_longname} (${row._source.geography_shortname})`}</Td>
                <Td data-testid="sector">{row._source.sector ? row._source.sector.join(', ') : ''}</Td>
                <Td data-testid="activity-type">
                  {capitalizeFirstLetter(activityTypeToDisplayName(row._source.activity_type))}
                </Td>
                <Td sx={{ textTransform: 'capitalize' }}>
                  <Link
                    as={RouterLink}
                    to={`/${version}/${system_model}/dataset/${row._source.spold_id}/documentation`}
                    onClick={() => {
                      onResultClick(row._source.spold_id);
                    }}
                  >
                    Details
                  </Link>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ActivitySearchResultTable;
