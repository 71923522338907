import { useEffect } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Heading,
  Link,
  Skeleton,
  Stack,
} from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import PageHead from 'components/shared/PageHead';
import { Dataset } from 'hooks/data/datasets/types';
import useDataset from 'hooks/data/datasets/useDataset';
import useVersionInfo from 'hooks/useVersionInfo';
import RelatedDatasets from 'pages/Dataset/components/RelatedDatasets';

import DatasetNotFound from './components/DatasetNotFound';
import PrivateDatasetView from './PrivateDatasetView';
import PublicDatasetView from './PublicDatasetView';

const DatasetView = () => {
  const { pathname } = useLocation();
  const { spold_id } = useParams();
  const navigate = useNavigate();

  const { ver, system_model } = useVersionInfo();
  const urlPath = pathname.split('/')[5];

  const { data, isLoading, isError } = useDataset(spold_id);

  useEffect(() => {
    if (!urlPath) {
      navigate('documentation', { replace: true });
    }
  }, [navigate, spold_id, urlPath]);

  const fullData: Dataset = data ?? {
    activity_name: 'Fallback activity name',
    geography: {
      short_name: 'GLO',
      long_name: 'Global',
      comment: 'Fallback geography comment',
    },
    reference_product: 'Fallback reference product',
    has_access: true,
    version: '',
    system_model: '',
    index: 0,
  };

  const pageTitle = [fullData.activity_name, fullData.geography.long_name, fullData.reference_product].every(Boolean)
    ? `${fullData.activity_name} - ${fullData.geography.long_name} - ${fullData.reference_product}`
    : 'Dataset';

  const isSystemModelUndefined = system_model === 'undefined';

  const unusableUndefinedPaths = ['lci', 'impact_assessment', 'consuming_activities'];
  const currentPath = location.pathname.split('/');

  if (isSystemModelUndefined && currentPath.some((path) => unusableUndefinedPaths.includes(path))) {
    currentPath.splice(currentPath.length - 1, 1, 'documentation');
    const newPath = currentPath.join('/');
    navigate(newPath, { replace: true });
  }

  return (
    <Stack alignItems={'center'} width="100%">
      <PageHead title={pageTitle} />
      {isError && !isLoading ? (
        <DatasetNotFound />
      ) : (
        <Stack spacing={5} maxWidth={'container.xl'} width={'90%'}>
          <Breadcrumb fontSize="sm">
            <BreadcrumbItem>
              <Skeleton isLoaded={!isLoading}>
                <BreadcrumbLink
                  as={RouterLink}
                  to={`/${ver}/${system_model}/search`}
                  href={`/${ver}/${system_model}/search`}
                  onClick={() => {
                    mixpanel.track('Breadcrumb Home Clicked', {
                      datasetId: spold_id,
                      version: ver,
                      system_model,
                    });
                  }}
                >
                  Home
                </BreadcrumbLink>
              </Skeleton>
            </BreadcrumbItem>

            <BreadcrumbItem fontWeight="medium" isCurrentPage>
              <Skeleton isLoaded={!isLoading}>
                <BreadcrumbLink>{fullData.activity_name}</BreadcrumbLink>
              </Skeleton>
            </BreadcrumbItem>
          </Breadcrumb>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <Heading as="h4">{fullData.activity_name}</Heading>
          </Skeleton>
          {fullData.has_access && <RelatedDatasets url_path={urlPath} id={spold_id as string} />}
          <Divider />
          {isSystemModelUndefined && fullData.has_access && (
            <Alert status="info" variant="left-accent" borderRadius="md">
              <AlertIcon />
              <AlertDescription>
                You’re currently viewing an ‘undefined’ (unlinked, multi-output) dataset.{' '}
                <Link
                  href={'https://support.ecoinvent.org/upr-lci-lcia'}
                  target="_blank"
                  title="Learn more about undefined datasets"
                  fontWeight={'semibold'}
                  colorScheme="blue"
                >
                  Learn more about the difference between linked and undefined datasets.
                </Link>
              </AlertDescription>
            </Alert>
          )}
          {fullData.has_access || isLoading ? (
            <PrivateDatasetView path={urlPath} isLoading={isLoading} />
          ) : (
            <PublicDatasetView datasetId={spold_id ?? ''} isLoading={isLoading} />
          )}
        </Stack>
      )}
    </Stack>
  );
};
export default DatasetView;
