import React from 'react';

import { Image, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import { FallbackProps } from 'react-error-boundary';

import logo from 'assets/ecoinvent_brand.png';

const Fallback = ({ error }: FallbackProps) => {
  const fallbackBg = useColorModeValue('gray.100', 'gray.900');

  return (
    <Stack spacing={5} alignItems="center" py={10} maxW={'container.md'} margin={'0 auto'}>
      <Image maxW="100px" src={logo} />
      <Stack alignItems="center">
        <Text as="h3" fontSize="3xl">
          Uh Oh...
        </Text>
        <Text>Something seems to have gone wrong on our side. Please try again later.</Text>
        {import.meta.env.DEV && (
          <Stack w="100%">
            <Text fontWeight={'medium'} fontSize={'sm'}>
              Error details
            </Text>
            <Stack p={2} borderRadius={'lg'} bg={fallbackBg} whiteSpace={'pre-wrap'}>
              <Text fontFamily={'mono'}>{error.message}</Text>
              <Text fontFamily={'mono'}>{error.stack}</Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Fallback;
