import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { FetchDatasetParams } from '../types';

import { DirectContributionResponse, UseDirectContributionParams } from './types';

const fetchDirectContibution = async (params: FetchDatasetParams & Pick<UseDirectContributionParams, 'indicator_id'>) => {
  const { data } = await api.get(`/spold/direct_contributions`, { params });

  return data;
};

const useDirectContribution = ({ dataset_id, indicator_id }: UseDirectContributionParams) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<DirectContributionResponse, AxiosError>(
    ['directContribution', dataset_id, indicator_id, version, system_model],
    () => fetchDirectContibution({ version, system_model, dataset_id, indicator_id }),
    { enabled: !!dataset_id && !!indicator_id }
  );
};

export default useDirectContribution;
