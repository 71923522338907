import { MultipleComboBox } from '@ecoinvent/ui';

import { ElasticBucket } from 'hooks/data/search/types';
import { capitalizeFirstLetter } from 'utilities/helpers/formats';

type Props = {
  onChange: (newSelectedItems?: ElasticBucket[]) => void;
  selectedItems: ElasticBucket[];
  options: ElasticBucket[];
  placeholder: string;
  displayName?: string;
};

const FilterComboBox = (props: Props) => {
  const { options, displayName, placeholder, onChange, selectedItems } = props;

  const stringifyOption = (option: ElasticBucket | null) => {
    switch (displayName) {
      case 'Activity type':
        return `${capitalizeFirstLetter(option?.key.split('_').join(' ').toLowerCase() ?? '')} (${option?.doc_count})`;
      case 'Sector':
        return `${capitalizeFirstLetter(option?.key.toLowerCase() ?? '')} (${option?.doc_count})`;
      default:
        return `${option?.key} (${option?.doc_count})`;
    }
  };

  return (
    <MultipleComboBox
      placeholder={placeholder}
      options={options}
      selectedItems={selectedItems}
      filterMenuOptions={(options, selectedItems, inputValue) =>
        options.filter((o) => selectedItems.indexOf(o) === -1 && o.key.toLowerCase().includes(inputValue?.toLowerCase() ?? ''))
      }
      onChange={onChange}
      itemToString={stringifyOption}
      colorScheme="green"
      menuProps={{ maxHeight: '300px' }}
    />
  );
};

export default FilterComboBox;
