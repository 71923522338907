export const releaseMdString = `
 
We're excited to announce the release of version 3.10 of the ecoinvent database on our new ecoQuery platform. This release enhances the user interface for a smoother experience and brings a wealth of updates to the ecoinvent database. Dive into what's new:

##### Database Update - Version 3.10:

- **Petroleum and Natural Gas**: Incorporates 2021 supply chain data.
- **Agriculture**: Broader regional data for key crops in Australia and the USA, plus in-depth machinery and field operations data.
- **Electricity**: Refreshed market mixes with 2020 and 2021 data for select countries.
- **Waste**: Detailed waste treatment chain data for nuanced environmental impact insights.
- **Chemicals**: Comprehensive unit process data for key chemical components.
- **Construction**: Regional data expansion for construction materials in Tunisia, Ecuador, and Switzerland.
- **Packaging**: Addition of data for beverage carton production.
- **Fuels**: Introduction of hard coal coke production data in China.
- **Impact Assessment Methods**: Integration of IMPACT World+ v2.0.1, USEtox v2.13, and more.
- **Additional Updates**: Enhanced detailing with chemical formulas, CAS numbers, and HS classifications.

##### ecoQuery Update - Revitalized Design:

- Updated design elements reflecting our new branding.
- Optimized Dataset Exchanges view for better clarity.
- Redesigned LCI results layout for efficient space utilization.
- New filter functionality for Consuming Activities.
- Resolved layout issues in Dataset Documentation.
- Improved app responsiveness, especially on smaller screens.
- Fixed some layout ambiguities on the Database Search page.
- Added breadcrumbs to improve navigation during searching.
- Enhanced loading experience to minimize layout shift.

Our latest release underlines our commitment to delivering current and precise environmental data to support informed, sustainable decision-making. [Explore version 3.10](https://support.ecoinvent.org/ecoinvent-version-3.10).
`;
