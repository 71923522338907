import { useMemo } from 'react';

import { Stack } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import NoResultCard from 'components/UI/NoResultCard';
import { DocumentationResponse } from 'hooks/data/datasets/documentation/types';
import useDatasetDocumentation from 'hooks/data/datasets/documentation/useDatasetDocumentation';

import DocumentationItem from './components/DocumentationItem';
import { buildDocumentationConfig, loadingConfig } from './documentationConfig';

type Props = {
  isLoading?: boolean;
};

const Documentation = ({ isLoading }: Props) => {
  const { spold_id } = useParams();

  const { data, isLoading: isDocumentationLoading } = useDatasetDocumentation(spold_id);

  const documentationConfig = useMemo(() => (data ? buildDocumentationConfig(data as DocumentationResponse) : []), [data]);

  const isAnythingLoading = isLoading || isDocumentationLoading;

  const configToRender = isLoading ? loadingConfig : documentationConfig;

  return (
    <Stack sx={{ maxWidth: '100%', pb: '6rem' }}>
      {!isAnythingLoading && !data?.activity_description ? (
        <NoResultCard>This dataset doesn't seem to have documentation.</NoResultCard>
      ) : (
        <Stack spacing={6}>
          {configToRender.map(({ label, value, renderValue }) => (
            <DocumentationItem
              isLoading={isAnythingLoading}
              key={label}
              label={label}
              value={value}
              renderValue={renderValue}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default Documentation;
