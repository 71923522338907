import React from 'react';

import { Stack, Text, useColorModeValue } from '@ecoinvent/ui';

import { CombinedExchange, ExchangeFilter } from '../../types';

import ExchangeUncertaintyView from './ExchangeUncertaintyView';

type Props = {
  item: CombinedExchange;
  enabledFeatures: ExchangeFilter[];
};

const ExchangeItemDetails = ({ item, enabledFeatures }: Props) => {
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');
  return (
    <Stack fontSize={'sm'} spacing={4}>
      {enabledFeatures.includes('comment') && (
        <Stack>
          <Text fontWeight={'medium'}>Comment</Text>
          {item.comment ? (
            <Text variant={'body2'}>{item.comment}</Text>
          ) : (
            <Text variant={'body2'} color={grayTextColor}>
              This exchange has no comment.
            </Text>
          )}
        </Stack>
      )}
      {enabledFeatures.includes('uncertainty') && (
        <Stack>
          <Text fontWeight={'medium'} fontSize={'sm'}>
            Uncertainty
          </Text>
          {item.uncertainty ? (
            <ExchangeUncertaintyView uncertainty={item.uncertainty} />
          ) : (
            <Text variant={'body2'} color={grayTextColor}>
              This exchange has no uncertainty data.
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default ExchangeItemDetails;
