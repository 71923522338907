import axios, { InternalAxiosRequestConfig } from 'axios';

import { userManagerInstance } from 'utilities/auth/oidc';
import { appConfig } from 'utilities/env';

const instance = axios.create({
  baseURL: appConfig.apiUrl,
});

export const tokenInterceptor = async (config: InternalAxiosRequestConfig) => {
  const user = await userManagerInstance.getUser();

  if (user) {
    config.headers['Authorization'] = `Bearer ${user.access_token}`;
  } else {
    delete config.headers['Authorization'];
  }

  return config;
};

instance.interceptors.request.use(tokenInterceptor);

export default instance;
