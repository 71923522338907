import { useEffect } from 'react';

import { useAuth } from 'react-oidc-context';

const Logout = () => {
  const { signoutRedirect } = useAuth();

  useEffect(() => {
    signoutRedirect();
  }, [signoutRedirect]);

  return null;
};
export default Logout;
