import { Skeleton } from '@ecoinvent/ui';

import useReleaseVersions from 'hooks/data/versions/useReleaseVersions';

import ModalSelector from './ModalSelector';

const ReleaseAndSystemModel = () => {
  const { data: allReleases, isLoading } = useReleaseVersions();

  return (
    <Skeleton width={isLoading ? '150px' : 'auto'} height={'32px'} isLoaded={!isLoading}>
      {allReleases ? <ModalSelector all_releases={allReleases ?? {}} /> : <></>}
    </Skeleton>
  );
};

export default ReleaseAndSystemModel;
