import React, { useState } from 'react';

import { Tr, IconButton, Collapse, Td, Box, Icon } from '@ecoinvent/ui';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { ExchangeProperty } from 'hooks/data/datasets/exchanges/types';

type Props = { item: ExchangeProperty };

const PropertyTableRow = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const hasCommentOrUncertainty = item.comment || item.uncertainty;

  return (
    <>
      <Tr h="45px" cursor={hasCommentOrUncertainty ? 'pointer' : ''} onClick={() => setOpen(!open)}>
        <Td>{item.name}</Td>
        <Td w={32} align="right">
          {item.amount.toFixed(4)}
        </Td>
        <Td w={32}>{item.unit}</Td>
        <Td w={16}>
          {(item.comment || item.uncertainty) && (
            <IconButton
              colorScheme="gray"
              variant={'ghost'}
              aria-label="expand row"
              size="sm"
              onClick={() => setOpen(!open)}
              icon={<Icon as={open ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
            />
          )}
        </Td>
      </Tr>
      <Tr>
        <Td py={0} colSpan={4}>
          {hasCommentOrUncertainty && (
            <Collapse in={open}>
              <Box p={4}>
                <b>Comment: </b> {item.comment}
              </Box>
            </Collapse>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default PropertyTableRow;
