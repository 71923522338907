import { Heading, Link, Box, OrderedList, UnorderedList } from '@ecoinvent/ui';
import { Components } from 'react-markdown';

import DocumentationImage from '../DocumentationImage';

export const markdownComponentMap: Components = {
  img: DocumentationImage,
  ul: (props) => <UnorderedList py={2} spacing={5} paddingInlineStart={10} {...props} />,
  ol: (props) => <OrderedList as="ol" py={2} spacing={5} paddingInlineStart={10} {...props} />,
  h1: ({ children }) => (
    <Heading as="h1" size="3xl">
      {children}
    </Heading>
  ),
  h2: ({ children }) => (
    <Heading as="h2" size="2xl">
      {children}
    </Heading>
  ),
  h3: ({ children }) => (
    <Heading as="h3" size="xl">
      {children}
    </Heading>
  ),
  h4: ({ children }) => (
    <Heading as="h4" size="lg">
      {children}
    </Heading>
  ),
  h5: ({ children }) => (
    <Heading as="h5" size="md">
      {children}
    </Heading>
  ),
  pre: ({ children }) => (
    <Box as="pre" whiteSpace={'pre-wrap'}>
      {children}
    </Box>
  ),
  a: (props) => <Link {...props} />,
};
