import { Alert, Link, Stack, Heading, AlertIcon, AlertDescription, Skeleton, Button, HStack, Text } from '@ecoinvent/ui';
import { useAuth } from 'react-oidc-context';

import PageHead from 'components/shared/PageHead';
import useFileAccess from 'hooks/data/files/useFileAccess';
import useFiles from 'hooks/data/files/useFiles';
import useReleaseVersions from 'hooks/data/versions/useReleaseVersions';
import DocumentationMarkdownRenderer from 'pages/Documentation/components/DocumentationMarkdownRenderer';

import loadingFiles from './loadingData';
import VersionFiles from './VersionFiles';

const Files = () => {
  const auth = useAuth();
  const { isLoading: areFilesLoading, data: files } = useFiles();
  const { data } = useFileAccess();
  const { isLoading: areVersionsLoading, data: versions } = useReleaseVersions();

  const filesToRender = files ?? loadingFiles;
  const hasFileAccess = data?.has_access ?? false;

  const orderedFilesToRender = filesToRender.sort((a, b) =>
    b.version_name.localeCompare(a.version_name, undefined, { numeric: true, sensitivity: 'base' })
  );

  const isAnythingLoading = areFilesLoading || areVersionsLoading;

  const versionMap = Object.keys(versions ?? {}).sort((a, b) =>
    b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' })
  );

  return (
    <Stack alignItems={'center'}>
      <PageHead title="Files" />
      <Stack maxW="container.xl" w="90%" spacing={7}>
        <Heading as="h1" size="2xl">
          Files
        </Heading>
        <DocumentationMarkdownRenderer components={{ a: (props) => <Link {...props} /> }}>
          Here you can download the database in ecoSpold2 format and access several supporting files for different versions of
          the ecoinvent database (for more information on the files, see [our step-by-step
          guide](https://support.ecoinvent.org/getting-started#step-by-step-guide)). The data type `.spold` can be overwritten
          to `.xml`, and thus all the unit process (UPR), cumulative inventory (LCI), and results of the impact assessment
          (LCIA) of any activity can be viewed in any XML editor.
        </DocumentationMarkdownRenderer>
        {!auth.isAuthenticated ? (
          <Alert status="info" variant={'left-accent'} borderRadius="md">
            <AlertIcon />
            <AlertDescription>
              To view and download files, please{' '}
              <Button
                variant={'link'}
                colorScheme="blue"
                onClick={() => auth.signinRedirect({ redirect_uri: window.location.href })}
              >
                sign in.
              </Button>
            </AlertDescription>
          </Alert>
        ) : !files?.length && !isAnythingLoading ? (
          <Alert status="info" variant="left-accent" borderRadius="md">
            <AlertIcon /> {hasFileAccess ? 'No files available.' : 'Your licence doesn’t give you access to files.'}
          </Alert>
        ) : (
          <HStack alignItems="flex-start" gap={6}>
            <Stack spacing={0} flexGrow={1} gap={8}>
              {orderedFilesToRender?.map((version) => (
                <Skeleton key={version.version_name} isLoaded={!isAnythingLoading}>
                  <VersionFiles versionFiles={version} />
                </Skeleton>
              ))}
            </Stack>
            <Stack spacing={1} textAlign="right" top={4} sx={{ position: 'sticky' }}>
              <Text fontWeight="600">Jump to</Text>
              {versionMap.map((version, idx) => (
                <Link key={idx} href={`#${version}`}>
                  {version}
                </Link>
              ))}
            </Stack>
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};

export default Files;
