import mixpanel from 'mixpanel-browser';
import { UseMutateAsyncFunction } from 'react-query';

import { File } from 'hooks/data/files/types';

export function shortenBytesHelper(n: number) {
  const k = n > 0 ? Math.floor(Math.log2(n) / 10) : 0;
  const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
  const count = Math.floor(n / Math.pow(1024, k));
  return count + rank;
}

export const downloadFileFroms3 = async (
  file_uuid: File['uuid'],
  handleDownload: UseMutateAsyncFunction<void, unknown, string, unknown>
) => {
  mixpanel.track('Download file', { file_uuid });
  await handleDownload(`/files/${file_uuid}`);
};
