import React from 'react';

import { AccordionIcon, Heading, Stack, Text } from '@ecoinvent/ui';
import { format, parseISO } from 'date-fns';
import { BiChevronDown } from 'react-icons/bi';

type VersionRowProps = {
  versionName: string;
  releaseDate: string;
};

const VersionRow: React.FC<VersionRowProps> = ({ versionName, releaseDate }) => {
  return (
    <Stack cursor="pointer" flexDir="row" flexWrap="wrap" justifyContent="space-between" alignItems="center" width="100%">
      <Stack justifyContent="flex-start" gap={0.5} textAlign="left">
        <Heading size={'md'} fontWeight={700} id={versionName}>
          Version {versionName}
        </Heading>
        <Text fontSize="sm">Released {releaseDate ? format(parseISO(releaseDate), 'PPP') : releaseDate}</Text>
      </Stack>
      <AccordionIcon as={BiChevronDown} />
    </Stack>
  );
};

export default VersionRow;
