import React from 'react';

import { Avatar, Button, HStack, Icon, Stack, StackProps, Text, useMenuButton } from '@ecoinvent/ui';
import { BiChevronDown } from 'react-icons/bi';

type Props = StackProps & { id: string; name?: string; email?: string; isLoading?: boolean; imageSrc?: string };

const UserMenuButton = ({ id, name, email, imageSrc, ...rest }: Props) => {
  const buttonProps = useMenuButton(rest);
  return (
    <HStack
      {...buttonProps}
      as={Button}
      variant="ghost"
      aria-label="account of current user"
      aria-controls={id}
      aria-haspopup="true"
      color="white"
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      px="2"
      py="3"
      fontSize="sm"
      _hover={{ bg: 'whiteAlpha.400' }}
      _active={{ bg: 'whiteAlpha.400' }}
    >
      <Avatar size="sm" name={name} src={imageSrc} />
      <Stack spacing={0} alignItems={'flex-start'} display={{ base: 'none', xl: 'flex' }}>
        <Text fontSize={'14px'} fontWeight="bold">
          {name}
        </Text>
        <Text fontSize={'small'}>{email}</Text>
      </Stack>
      <Icon as={BiChevronDown} />
    </HStack>
  );
};

export default UserMenuButton;
