import { Box, Stack, HStack, IconButton, Link, useColorMode, Icon, DarkMode, useColorModeValue, Button } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { FiExternalLink, FiMoon, FiSun } from 'react-icons/fi';
import { useAuth } from 'react-oidc-context';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import logo from 'assets/ecoQuery-white.svg';
import useVersionInfo from 'hooks/useVersionInfo';

import QuickSearch from './QuickSearch';
import ReleaseAndSystemModel from './ReleaseAndSystemModel';
import UserMenu from './UserMenu';
import WhatsNew from './WhatsNew';

const NavBar = () => {
  const { system_model, ver } = useVersionInfo();
  const auth = useAuth();

  const location = useLocation();

  const shouldShowQuickSearch = !location.pathname.includes('search') && location.pathname !== '/';

  const { colorMode, toggleColorMode } = useColorMode();

  const navBarBg = useColorModeValue('green.500', 'green.700');

  return (
    <HStack w="100%" justifyContent={'space-between'} py={3} bg={navBarBg} minH={16} px={6}>
      <HStack spacing={5}>
        <RouterLink
          to={`/${ver}/${system_model}/search`}
          onClick={() => {
            mixpanel.track('Logo Clicked', {
              version: ver,
              system_model: system_model,
            });
          }}
        >
          <HStack spacing={3}>
            <Box width={'140px'}>
              <img alt="brand logo" src={logo} />
            </Box>
          </HStack>
        </RouterLink>
        <ReleaseAndSystemModel />
        <DarkMode>
          <IconButton
            aria-label="Toggle color mode"
            size="sm"
            variant={'ghost'}
            color="white"
            icon={<Icon as={colorMode === 'light' ? FiMoon : FiSun} />}
            onClick={toggleColorMode}
          />
        </DarkMode>
      </HStack>
      <Stack spacing={6} direction="row" alignItems={'center'}>
        {shouldShowQuickSearch && <QuickSearch />}
        {auth.isAuthenticated && (
          <Button
            size="sm"
            rightIcon={<Icon as={FiExternalLink} />}
            color="white"
            as={Link}
            variant={'link'}
            isExternal
            onClick={() => {
              mixpanel.track('Support Clicked');
            }}
            href={'https://support.ecoinvent.org'}
          >
            Support
          </Button>
        )}
        <WhatsNew />

        <Link fontSize="sm" color="white" as={RouterLink} to={`/${ver}/${system_model}/files`}>
          Files
        </Link>
        <Link fontSize="sm" color="white" as={RouterLink} to={`/${ver}/${system_model}/reports`}>
          Reports
        </Link>

        <UserMenu />
      </Stack>
    </HStack>
  );
};

export default NavBar;
