import { useCallback } from 'react';

import { Skeleton, Stack, Button, Heading } from '@ecoinvent/ui';

import { supportedFilters } from 'hooks/data/search/constants';
import { ElasticBucket, FilterMap, SpoldDataResponse, SupportedFilter } from 'hooks/data/search/types';
import useSearchFilters from 'hooks/data/search/useSearchFilters';

import FilterComboBox from './FilterComboBox';

type Props = {
  filters: FilterMap;
  aggregations: SpoldDataResponse['aggregations'] | undefined;
  isLoading?: boolean;
};

const ActivitySearchFilters = ({ filters, aggregations, isLoading }: Props) => {
  const { setBulkFilterValues, resetFilters } = useSearchFilters();

  const onFilterChange = (field: keyof FilterMap, data: ElasticBucket[]) => {
    setBulkFilterValues({
      currentPage: 1,
      [field]: data?.map((item) => item.key) ?? [],
    });
  };

  const buildSelectedItemList = useCallback(
    (f: SupportedFilter) => {
      const tempList = aggregations?.[f.field].buckets.filter((o) => filters[f.field].indexOf(o.key) != -1) ?? [];
      const zeroItemsList = [];
      for (const item of filters[f.field]) {
        if (aggregations?.[f.field].buckets.find((o) => o.key === item)) {
          return tempList;
        } else {
          zeroItemsList.push({ key: item, doc_count: 0 });
        }
      }
      return [...tempList, ...zeroItemsList];
    },
    [aggregations, filters]
  );

  return (
    <Stack maxW={{ xs: '100%', lg: '300px' }} spacing={2} w="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Heading display={{ xs: 'none', md: 'block' }} size="sm">
          Filters
        </Heading>
        {(Object.keys(filters) as (keyof FilterMap)[]).some((key) => filters[key].length !== 0) && (
          <Button variant="link" size="sm" onClick={resetFilters}>
            Clear All
          </Button>
        )}
      </Stack>
      {isLoading && !aggregations ? (
        <>
          <Skeleton variant="rounded" height={'30px'} />
          <Skeleton variant="rounded" height={'30px'} />
          <Skeleton variant="rounded" height={'30px'} />
          <Skeleton variant="rounded" height={'30px'} />
          <Skeleton variant="rounded" height={'30px'} />
        </>
      ) : (
        aggregations &&
        Object.keys(aggregations).length &&
        supportedFilters.map((f) => (
          <FilterComboBox
            key={f.label}
            displayName={f.label}
            placeholder={f.label}
            onChange={(v) => {
              onFilterChange(f.field, v ?? []);
            }}
            selectedItems={buildSelectedItemList(f)}
            options={aggregations[f.field].buckets}
          />
        ))
      )}
    </Stack>
  );
};
export default ActivitySearchFilters;
